import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import "./headsup.scss";
import {
  tictellLogin,
  getCurrenciesApi,
  tictellAppLoginApi,
} from "../Tictell/tictellApi";
import { privateRoutes } from "../../Routes/routing";
import {
  GetAcceptedCollaborationCount,
  getChildFeatureByName,
} from "../Snapshot/snapshotApi";
import { StorageService } from "../../api";
import SnapshotNavigation from "./Navigation/Navigation";
import Shortlist from "./views/Shortlist/Shortlist";
import { ConfirmDialog } from "../../common";
import Opportunity from "./views/Opportunity/Opportunity";
import Dashboard from "./views/Dashboard/Dashboard";
import SavedSearch from "./views/SavedSearch/SavedSearch";

class Headsup extends Component {
  state = {
    UserFeatures: [],
    token: "",
    ExpenseCategories: [],
    Currencies: [],
    isLoading: true,
    UserId: -1,
    dialogMessage: "",
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const firstUrl = this.state.UserFeatures.find((a) => a.Url);
    var queryString = window.location.href;
    queryString = queryString.toLowerCase().split("headsup")[1];
    if (queryString == "") {
      if (firstUrl) {
        const isApp = window.location.hash.toLowerCase().indexOf("apps") > -1;
        this.props.history.push(
          (isApp ? "/apps" : "") + "/headsup/" + firstUrl.Url
        );
      }
    }
  }
  componentDidMount() {
    getChildFeatureByName({ featureName: 'headsup'  })
      .then((res) => {
        this.setState({
          UserFeatures: res.items,
        });
        const { items } = res;
        const firstUrl = items.find((a) => a.Url);
        var queryString = window.location.href;
        queryString = queryString.toLowerCase().split("headsup")[1];
        if (queryString == "") {
          if (firstUrl) {
            const isApp =
              window.location.hash.toLowerCase().indexOf("apps") > -1;
            this.props.history.push(
              (isApp ? "/apps" : "") + "/headsup/" + firstUrl.Url
            );
          }
        }
      })
      .catch((err) => console.log("Err ", err));

    let { UserEmail } = this.props.User;
    let Email = UserEmail;
    if (!Email) {
      const User = StorageService.getUser();
      Email = User.Email;
    }
    this.setState({
      UserId: this.props.User.UserId,
    });
    tictellAppLoginApi(Email)
      .then((res) => {
        if (res.success) {
          tictellLogin(Email)
            .then((res) => {
              if (res.success) {
                const { access_token } = res.items;
                this.getCurrencies(access_token);
                this.getAllactivecollaborations(access_token);
                this.setState({
                  token: access_token,
                });
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((Err) => console.log("Error ", Err));
  }
  getAllactivecollaborations(access_token) {
    GetAcceptedCollaborationCount(access_token).then((res) => {
      if (res.success) {
        if (res.items.length === 0) {
          this.setState({
            dialogMessage: this.props.labels.Snapshot_Collaboration_Dialog_Msg,
            isLoading: true,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    });
  }
  getCurrencies(access_token) {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          this.setState({
            Currencies: res.items.map((item) => ({
              ...item,
              value: item.CurrencyId,
              label: item.Name,
            })),
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleOkClick = () => {
    this.props.history.push(privateRoutes.dashboard.path);
  };
  render() {
    const { labels, isHelpActive, location } = this.props;
    const {
      UserFeatures,
      token,
      Currencies,
      dialogMessage,
      ExpenseCategories,
      isLoading,
      UserId,
      pendingtimesheet,
      Rejectedtimesheet,
      Rejectedexpense,
      Pendingexpense,
    } = this.state;
    const hashesList = [
      "#/apps/headsup/",
      "#/headsup/",
      "#/apps/headsup",
      "#/headsup",
    ];
    const currentViewHash =
      window.location.hash.toLowerCase().split("/")[1] == "apps"
        ? window.location.hash.toLocaleLowerCase().split("/")[3]
        : window.location.hash.toLocaleLowerCase().split("/")[2];
    return (
      <PageWrapper className={`headsup-page`}>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <SnapshotNavigation
          disabled={`${isLoading ? "disabled" : ""}`}
          labels={labels}
          isHelpActive={isHelpActive}
          UserFeatures={UserFeatures}
        />
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleOkClick}
              >
                {"Ok"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!isLoading && (
          <React.Fragment>
            {currentViewHash == "home" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <Dashboard
                  token={token}
                  Readyforapproval={pendingtimesheet}
                  Rejected={Rejectedtimesheet}
                  asOwnerCount={"12"}
                  Rejectedexpense={Rejectedexpense}
                  Pendingexpense={Pendingexpense}
                  UserFeatures={UserFeatures}
                />
              )}
            {currentViewHash == "shortlist" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <Shortlist
                  token={token}
                  Currencies={Currencies}
                  ExpenseCategories={ExpenseCategories}
                />
              )}
            {currentViewHash == "opportunity" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <Opportunity
                  token={token}
                  Currencies={Currencies}
                  ExpenseCategories={ExpenseCategories}
                  UserId={UserId}
                  locationProp={location}
                />
              )}
            {currentViewHash == "savedsearch" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <SavedSearch
                  token={token}
                  Currencies={Currencies}
                  ExpenseCategories={ExpenseCategories}
                  UserId={UserId}
                />
              )}
          </React.Fragment>
        )}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Headsup);
