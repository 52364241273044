import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import {
  Column,
  ConfirmDialog,
  Button,
  EmptyInfo,
  LoadingMask,
} from "../../common";
import ImgSrc from "../../assets/images/present.svg";

import PresentList from "./components/PresentList";
import PresentFormEditor from "./components/PresentFormEditor";
import {
  isValidURL,
  loadImageOrientation,
  isValidPhoneNumber,
} from "../../utilities/helpers";
import find from "lodash/find";
import filter from "lodash/filter";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import startCase from "lodash/startCase";
import includes from "lodash/includes";
import toLower from "lodash/toLower";

import {
  presentApi,
  presentDeleteApi,
  getResumesApi,
  getUserEmailsApi,
  getUserPhonenumbersApi,
  presentSubmitApi,
  updatePresentApi,
} from "./presentApi";
import { notificationAction } from "../../actions";
import "./present.scss";

import { onStateChangeAction } from "./presentAction";
import { Col, Row, Space, Input, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PresentationFormNew from "./components/newForm";
import MobileFilter from "./components/mobileFilter.js";
import ArrowLeft from "../../assets/images/Icons/arrow-left.png";
import DeleteIcon from '../../assets/images/Icons/delete-modal.png'
import DeleteModal from "../../common/deleteModal/index.js";

const { Search } = Input;
const { Title } = Typography

class Present extends Component {
  state = {
    isMobileOpen: false
  };

  componentDidMount() {
    this.props.isFetching && this.getPresentList();
    this.getResumes();
    this.getUserEmails();
    this.getUserPhonenumbers();
  }

  getResumes = () => {
    getResumesApi().then((data) => {
      const profiles = data.items.map((item) => ({
        ...item,
        value: item.ProfileId,
        label: item.ProfileValue,
      }));
      this.props.onStateChangeAction({ profiles });
    });
  };
  getUserEmails = () => {
    getUserEmailsApi().then((data) => {
      const filterEmail = data.items.filter((item) => item.UserEmailValue);
      const userEmails = filterEmail.map((item) => ({
        ...item,
        value: item.UserEmailValue,
        label: item.UserEmailValue,
      }));
      this.props.onStateChangeAction({
        userEmails,
      });
    });
  };
  getUserPhonenumbers = () => {
    getUserPhonenumbersApi().then((data) => {
      const filterPhone = data.items.filter(
        (item) => item.UserPhonenumberValue
      );
      const userPhonenumbers = filterPhone.map((item) => ({
        ...item,
        value: item.UserPhonenumberValue,
        label: item.UserPhonenumberValue,
      }));
      this.props.onStateChangeAction({
        userPhonenumbers,
      });
    });
  };

  getPresentList = () => {
    this.props.onStateChangeAction({ isFetching: true });
    presentApi()
      .then((data) => {
        let presentations = [];
        if (data.items.length > 0) {
          presentations = map(data.items, (presentation) => {
            const newItem = {
              ...presentation,
              selectedPhoneNumber: {
                ...presentation.UserPhonenumbers,
                value:
                  presentation.UserPhonenumbers &&
                  presentation.UserPhonenumbers.UserPhonenumberId,
                label:
                  presentation.UserPhonenumbers &&
                  presentation.UserPhonenumbers.UserPhonenumberValue,
              },
              IsDefault: presentation.IsDefault == true ? true : false,
              IsChecked: presentation.IsDefault == true ? true : false,
            };
            return newItem;
          });
        } else {
          presentations = [];
        }
        const currentPresentation =
          this.props.location.state &&
          presentations.find(
            (item) =>
              item.UserDetailId ===
              this.props.location.state.present.UserDetailId
          );
        const presentForm = isEmpty(currentPresentation)
          ? isEmpty(presentations)
            ? {}
            : head(presentations)
          : currentPresentation;
        this.props.onStateChangeAction({
          presentList: presentations,
          filteredList: presentations,
          isLoading: false,
          isFetching: false,
          presentForm,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetching: false,
          isLoading: false,
        });
      });
  };

  handleCreateNewClick = () => {
    var { profiles } = this.props;
    if (profiles.length === 0) {
      const info = {
        message: this.props.labels.Present_ProfileNotFound,
        status: "info",
      };
      this.props.notificationAction(info);
    } else {
      const presentForm = { UserDetailId: -1 };
      this.props.onStateChangeAction({ presentForm });
    }
    if (
      window?.innerWidth < 767
    ) {
      this.setState(st => ({ ...st, isMobileOpen: true }))
    }
  };

  handlePresentClick = (id) => {
    var { presentList } = this.props;
    const presentForm = find(presentList, { UserDetailId: id });
    this.props.onStateChangeAction({
      presentForm,
    });
    if (
      window?.innerWidth < 767
    ) {
      this.setState(st => ({ ...st, isMobileOpen: true }))
    }
  };

  handleDeletePresent = (id) => {
    this.props.onStateChangeAction({
      deletedId: id,
      dialogMessage: this.props.labels.presentDeleteConfirmation,
    });
  };

  handleYesClick = () => {
    const { deletedId } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: "",
      isLoading: true,
    });
    presentDeleteApi({ id: deletedId })
      .then((data) => {
        if (data) {
          this.getPresentList();
          const info = {
            message: data.message,
            status: data.success ? "success" : "info",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            presentForm: {},
            deletedId: null,
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      deletedId: null,
    });
  };

  handleFormSelectChange = (name, childName, selectedOption) => {
    if (!selectedOption) return;
    const { presentForm, userPhonenumbers } = this.props;
    const { value, UserPhonenumberId } = selectedOption;
    if (name === "UserPhonenumbers") {
      if (UserPhonenumberId) {
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            selectedPhoneNumber: selectedOption,
            Phone: UserPhonenumberId,
            UserPhonenumberId,
          },
        });
        return;
      }
      const newPhoneNumber = head(userPhonenumbers);
      if (
        newPhoneNumber &&
        newPhoneNumber.value &&
        !isValidPhoneNumber(newPhoneNumber.value)
      ) {
        userPhonenumbers.splice(0, 1);
        this.props.onStateChangeAction({
          userPhonenumbers: [...userPhonenumbers],
        });
        return;
      } else {
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            newPhoneCreated: true,
            Phone: value,
            selectedPhoneNumber: selectedOption,
          },
        });
        return;
      }
    }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: {
          ...presentForm[name],
          ...selectedOption,
        },
        invalidRole: name === "Profiles" && !selectedOption.value,
      },
    });
  };

  handleSelectBlur = (e) => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        invalidRole: presentForm && !presentForm.Profiles,
      },
    });
  };

  validateUrl = ({ name, value }) => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: value,
        [`invalid${name}`]: !(value && isValidURL(value)),
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { presentForm } = this.props;
    if (name === "WebUrl") {
      this.validateUrl({ name, value });
      return;
    }
    if (name === "IsDefault") {
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          [name]: !presentForm.IsDefault,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        [name]: value,
        [`invalid${name}`]: !value,
      },
    });
  };

  handleFormFieldBlur = (e) => {
    let { name, value } = e.target;
    const { presentForm } = this.props;
    if (name === "WebUrl") {
      if (
        !isEmpty(value) &&
        value.indexOf("http") < 0 &&
        value.indexOf("https") < 0 &&
        value.indexOf("ftp") < 0
      ) {
        value = "http://" + value;
        this.props.onStateChangeAction({
          presentForm: {
            ...presentForm,
            WebUrl: value,
          },
        });
      }
      this.validateUrl({ name, value });
      return;
    }
  };

  updateAvatar = (image) => {
    var { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        Logo: image,
      },
    });
  };

  handleFileChange = (file) => {
    if (file) {
      loadImageOrientation(file, this.updateAvatar);
      file = "";
    }
  };

  handleGetImgSrc = () => {
    const { presentForm } = this.props;
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        Logo: null,
      },
    });
  };

  handleSubmitPresent = () => {
    const { presentForm } = this.props;
    let { WebUrl } = presentForm;
    const {
      Title,
      UserName,
      Detail,
      Profiles,
      UserEmails,
      UserDetailId,
      Logo,
      selectedPhoneNumber,
      IsDefault,
    } = presentForm;
    if (!UserName || !Profiles || !Title) {
      const { presentForm } = this.props;
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          invalidTitle: !Title,
          invalidRole: !Profiles,
          invalidUserName: !UserName,
        },
      });
      const info = {
        message: this.props.labels.presentFixValidation,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    //WebURL Validation
    if (
      !isEmpty(WebUrl) &&
      WebUrl.indexOf("http") < 0 &&
      WebUrl.indexOf("https") < 0 &&
      WebUrl.indexOf("ftp") < 0
    ) {
      WebUrl = "http://" + WebUrl;
      this.props.onStateChangeAction({
        presentForm: {
          ...presentForm,
          WebUrl: WebUrl,
        },
      });
    }

    if (!isEmpty(WebUrl) && !isValidURL(WebUrl)) {
      const info = {
        message: this.props.labels.presentWebUrlInvalid,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    // //End WebURL Validation
    // const invalidUrls = filter(SocialLinks, (social) => {
    //   if (!isEmpty(social.URL) && !isValidURL(social.URL)) return social;
    // });

    // if (!isEmpty(invalidUrls)) {
    //   const invalidUrl = head(invalidUrls).Name;
    //   const info = {
    //     message: this.props.labels[`Invalid${startCase(invalidUrl)}Url`],
    //     status: "error",
    //   };
    //   this.props.notificationAction(info);
    //   return;
    // }
    this.props.onStateChangeAction({
      presentForm: {
        ...presentForm,
        isSubmitting: true,
        invalidWebUrl: false,
      },
    });

    const presentObject = {
      Title,
      UserName,
      WebUrl,
      Detail,
      UserDetailId: UserDetailId,
      ProfileId: Profiles && Profiles.ProfileId,
      UserEmailId: UserEmails && UserEmails.UserEmailId,
      Email: UserEmails && UserEmails.UserEmailId,
      UserPhonenumberId:
        selectedPhoneNumber && selectedPhoneNumber.UserPhonenumberId,
      Logo,
      UserPhonenumberValue: selectedPhoneNumber && selectedPhoneNumber.value,
      IsDefault,
    };
    this.props.onStateChangeAction({ isLoading: true });
    //If UserDetailId = -1 its mean we are adding new presentation so we call post api.
    if (UserDetailId === -1) {
      presentSubmitApi(presentObject)
        .then((data) => {
          if (data.success) {
            this.getPresentList();
            const info = {
              message: this.props.labels.presentSuccessfullySaved,
              status: "success",
            };
            this.props.notificationAction(info);
          }
          const presentForm = { UserDetailId: -1 };
          this.props.onStateChangeAction({
            presentForm,
            isLoading: false,
          });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        })
        .catch(() => {
          this.setState((st) => ({ ...st, isMobileOpen: false }));
          this.props.onStateChangeAction({ isLoading: false });
        });
    }
    //If UserDetailId != 0  its mean we are updating presentation so we call put api.
    if (UserDetailId !== -1) {
      presentObject.Profiles = presentForm && presentForm.Profiles;
      presentObject.UserPhonenumbers =
        presentForm && presentForm.UserPhonenumbers;
      presentObject.UserEmails = presentForm && presentForm.UserEmails;
      presentObject.Email = presentObject.UserEmailId;
      presentObject.Phone = presentObject.UserPhonenumberId;
      updatePresentApi(presentObject)
        .then((data) => {
          if (data.success) {
            this.getPresentList();
            const info = {
              message: this.props.labels.presentSuccessfullySaved,
              status: "success",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({ isLoading: false });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        })
        .catch(() => {
          this.props.onStateChangeAction({ isLoading: false });
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        });
    }
  };

  handlePresentationSearch = (e) => {
    const searchKey = e.target.value;
    const { presentList } = this.props;
    this.props.onStateChangeAction({
      searchKey,
    });
    const filteredList = filter(presentList, (presentation) => {
      if (includes(toLower(presentation.Title), toLower(searchKey))) {
        return presentation;
      }
    });
    this.props.onStateChangeAction({ filteredList });
  };

  render() {
    const {
      listCollapse,
      formCollapse,
      filteredList,
      presentForm,
      searchKey,
      dialogMessage,
      profiles,
      userEmails,
      userPhonenumbers,
      isLoading,
      isFetching,
    } = this.props;

    const { labels, isHelpActive } = this.props;
    const sytemLabel = labels;

    return (
      <div className="company-page-main h-100">

        <PageWrapper className="company-page">
          {dialogMessage && (
            <ConfirmDialog testId="present-confirm-diloag">
              <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
              <ConfirmDialog.ActionButtons>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="present-delete-yes"
                  onClick={this.handleYesClick}
                >
                  {labels.presentDeleteCurtainYESBtnText}
                </ConfirmDialog.Button>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="present-delete-no"
                  onClick={this.handleNoClick}
                >
                  {labels.presentDeleteCurtainNOBtnText}
                </ConfirmDialog.Button>
              </ConfirmDialog.ActionButtons>
            </ConfirmDialog>
          )}
          {this?.state?.isMobileOpen ?
            <div className="d-flex w-100 h-100" style={{ flexDirection: "column", overflow: "auto" }}>
              <div className="d-flex align-items-center gap-8 cursor-pointer"
                onClick={() => { this.setState(st => ({ ...st, isMobileOpen: false })) }}
              >
                <div>
                  <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                </div>
                <Title level={5} className="m-0">{labels?.presentation_title}</Title>
              </div>
              <div className="h-100 mt-3" style={{ overflow: "auto", background: "#fff", borderRadius: "12px" }}>
                <PresentationFormNew
                  labels={this?.props?.labels}
                  isLoading={isLoading}
                  profiles={profiles}
                  userEmails={userEmails}
                  userPhonenumbers={userPhonenumbers}
                  presentForm={presentForm}
                  onFormFieldChange={this.handleFormFieldChange}
                  onFormFieldBlur={this.handleFormFieldBlur}
                  onSubmitPresent={this.handleSubmitPresent}
                  onFileChange={this.handleFileChange}
                  onSelectBlur={this.handleSelectBlur}
                  onFormSelectChange={this.handleFormSelectChange}
                  getImgSrc={this.handleGetImgSrc}
                  handleSave={this.handleSubmitPresent}
                  getUserPhonenumbers={this.getUserPhonenumbers}
                  handleDelete={this.handleDeletePresent}
                />
              </div>
            </div>
            :

            <Row className="h-100 new-design-search w-100" >
              <Col xs={24} md={8} className="shortlist-lsit-section">
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3"
                    }}
                  >
                    <Input
                      placeholder={labels?.presentSearchFieldPlaceholder}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={this.handlePresentationSearch}
                    />
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {filteredList?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {filteredList?.map((item) => (
                          <div
                            onClick={() => this.handlePresentClick(item?.UserDetailId)}
                            key={item?.UserDetailId}
                            className={`d-flex gap-8 justify-content-between align-items-center pointer ${presentForm?.UserDetailId === item?.UserDetailId
                              ? "background-shortlist short-list-item "
                              : "short-list-item"
                              }`}>
                            <div className="d-flex text-clamp align-items-center gap-8">
                              <div className={`${item?.Logo ? "" : "no-dp"}`}>
                                <img
                                  className="roundImg"
                                  src={item?.Logo ? item?.Logo : ImgSrc}
                                  alt={item?.UserName}
                                  style={{ width: "48px", height: "48px", borderRadius: "50%" }}
                                />
                              </div>
                              <div className="text-clamp">
                                <label style={{ fontSize: "14px" }}>
                                  {item.Title}
                                </label>
                                <p className="text-clamp" style={{ fontSize: "12px", marginBottom: 0 }}>{item.UserName},  {(item?.Profiles && item?.Profiles?.ProfileValue) || ""}</p>
                              </div>
                            </div>
                            {presentForm?.UserDetailId === item?.UserDetailId &&
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeletePresent(item?.UserDetailId);
                                }}
                              >
                                <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                              </div>
                            }
                          </div>
                        ))}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  <div style={{ padding: "12px", borderTop: "1px solid #F4F2FE" }}>
                    <button className="button-last-footer w-100"
                      onClick={() => {
                        this.handleCreateNewClick()
                      }}
                    >
                      {labels?.add_presentaion}
                    </button>
                  </div>
                </div>
              </Col>

              <Col md={16} xs={0} offset={0.5} className="h-100">
                <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                  <PresentationFormNew
                    labels={this?.props?.labels}
                    isLoading={isLoading}
                    profiles={profiles}
                    userEmails={userEmails}
                    userPhonenumbers={userPhonenumbers}
                    presentForm={presentForm}
                    onFormFieldChange={this.handleFormFieldChange}
                    onFormFieldBlur={this.handleFormFieldBlur}
                    onSubmitPresent={this.handleSubmitPresent}
                    onFileChange={this.handleFileChange}
                    onSelectBlur={this.handleSelectBlur}
                    onFormSelectChange={this.handleFormSelectChange}
                    getImgSrc={this.handleGetImgSrc}
                    handleSave={this.handleSubmitPresent}
                    getUserPhonenumbers={this.getUserPhonenumbers}
                    handleDelete={this.handleDeletePresent}
                  />
                </div>
              </Col>
            </Row>
          }
        </PageWrapper>
      </div >
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, present }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;

  return { labels, user, isHelpActive, ...present };
};
const mapActionToProps = {
  onStateChangeAction,
  notificationAction,
};
export default connect(mapStateToProps, mapActionToProps)(Present);
