import React, { Component } from "react";
import { Column, Button } from "../../common";
import {
  getNetworkusers,
  networkInvitationSend,
  sendNetworkSignupInvitation
} from "./networkApi";
import { connect } from "react-redux";
import moment from "moment";
import { find } from "lodash";
import NetworkList from "./components/NetworkList";
import NetworkForm from "./components/NetworkForm";
import NetworkDetail from "./components/NetworkDetail";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";

import "./network.scss";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { onStateChangeAction } from "../Present/presentAction";

import {
  getInvitedNetworkusers,
  getInvitationsNetworkusers,
  acceptOpportunityApi,
  declineNetworkInviteApi,
  declineNetworkInvitationApi,
  getResumeByUserId,
  acceptOpportunityApiThroughNetwork,
  getOpportunity,
  getUserDetailApi
} from "./networkApi";

import {
  sendOpportunityApi,
} from "../Opportunity/opportunityApi";

import {
  postShortlistApi,
  getShortlistApi
} from "../Search/searchApi";

import {
  getNetworkPresentApi,
} from "../Present/presentApi";

import {
  getNetworkUserCompanyApi,
} from "../Company/companyApi";
import UserDetail from "../Collaboration/common/components/UserDetail";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Select } from "antd";


class Network extends Component {
  state = {
    dialogMessage: "",
    networkUsers: [],
    selectedUser: [],
    filteredList: [],
    companyList: [],
    currentPresent: [],
    Profiles: [],
    Emails: [],
    isConnectedView: false,
    isInvitedView: false,
    isInvitationsView: false,
    networkData:
    {
      email: ""
    },
    detailCollapsed: false,
    isCreateNew: false,
    listCollapsed: false,
    active: "",
    searchKey: "",
    ShortListId: [],
    windowWidth: window?.innerWidth
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.getNetUsersApi();
    window.addEventListener("resize", this.handleResize);
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getNetworkUserCompanyList = (selectedUser) => {
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getNetworkUserCompanyApi(selectedUser.UserId)
      .then(data => {
        let companies = data.items;
        let defaultCompany = [];
        defaultCompany = companies.Companies.find(item => item.IsDefaultCompany);
        if (defaultCompany == null || defaultCompany == undefined) {
          defaultCompany = companies[0];
        }
        this.setState({
          companyList: defaultCompany,
        });
        this.props.onStateChangeAction({
          companyList: defaultCompany,
          isLoading: false,
          isFetching: false
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false, isFetching: false });
      });
  };

  getNetworkUserPresent = (selectedUser) => {
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getNetworkPresentApi(selectedUser.UserId)
      .then(data => {
        let presentation = [];
        let profile = [];
        let email = [];
        presentation = data.items[0];
        profile = presentation.Profiles;
        email = presentation.UserEmails;
        this.setState({
          currentPresent: presentation,
          Profiles: profile,
          Emails: email,
        });
        this.props.onStateChangeAction({
          isLoading: false,
          isFetching: false,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetching: false,
          isLoading: false
        });
      });
  };

  getNetUsersApi = () => {
    const { isConnectedView, isInvitedView, isInvitationsView } = this.props;
    if (isConnectedView) {
      getNetworkusers().then(data => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    }

    else if (isInvitedView) {
      getInvitedNetworkusers().then(data => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    }

    else if (isInvitationsView) {
      getInvitationsNetworkusers().then(data => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    }
  };

  handleAcceptNetwork = obj => {
    acceptOpportunityApi(obj.UserId).then(data => {
      if (data.success) {
        let users = this.getNetUsersApi();
        if (users == null) {
          this.setState({
            selectedUser: [],
            companyList: [],
            currentPresent: [],
            Profiles: [],
            Emails: [],
          })
        }
        const info = {
          message: data.message,
          status: "success"
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error"
        };
        this.props.notificationAction(info);
      }
    });
  };

  handleDeleteNetwork = obj => {
    const { isInvitedView, isInvitationsView } = this.props;
    if (isInvitedView) {
      declineNetworkInviteApi(obj.UserId).then(data => {
        if (data.success) {
          let users = this.getNetUsersApi();
          if (users == null) {
            this.setState({
              selectedUser: [],
              companyList: [],
              currentPresent: [],
              Profiles: [],
              Emails: [],
            })
          }
          const info = {
            message: data.message,
            status: "success"
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: data.message,
            status: "error"
          };
          this.props.notificationAction(info);
        }
      });
    }
    else if (isInvitationsView) {
      declineNetworkInvitationApi(obj.UserId).then(data => {
        if (data.success) {
          let users = this.getNetUsersApi();
          if (users == null) {
            this.setState({
              selectedUser: [],
              companyList: [],
              currentPresent: [],
              Profiles: [],
              Emails: [],
            })
          }
          const info = {
            message: data.message,
            status: "success"
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: data.message,
            status: "error"
          };
          this.props.notificationAction(info);
        }
      });
    }
  };

  handleSendSignUpInvitationClick = () => {
    const { Email } = this.state.networkData;
    sendNetworkSignupInvitation(Email).then(data => {
      if (data.success) {
        const info = {
          message: data.message,
          status: "success"
        };
        this.setState({
          networkData: {
            ...this.state.networkData,
            Email: ""
          }
        })
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error"
        };
        this.props.notificationAction(info);
      }
    });
    this.setState({
      dialogMessage: ""
    });
  };

  handleItemClick = id => {
    const { networkUsers } = this.state;
    const selectedUser = find(networkUsers, { UserId: id });
    this.getNetworkUserCompanyList(selectedUser);
    this.getNetworkUserPresent(selectedUser);
    this.setState({
      selectedUser,
      isCreateNew: false
    })
  };


  handleSearchChange = e => {
    const { value } = e.target;
    const { networkUsers } = this.state;
    const filteredList = filter(networkUsers, sl =>
      includes(toLower(sl.UserFirstname), toLower(value))
    );
    this.setState({ filteredList, searchKey: value });
  };
  handleFormFieldChange = e => {
    const { name, value } = e.target;
    const { networkData } = this.state;
    this.setState({
      networkData: {
        ...networkData,
        [name]: value,
      },
    });
  };

  handleNewNetwork = () => {
    this.setState({ isCreateNew: true })
  }
  handleNetworkInvitationSend = () => {
    const { Email } = this.state.networkData;
    if (!Email) {
      const info = {
        message: "Please Enter Email",
        status: "error"
      };
      this.props.notificationAction(info);
      return
    }
    networkInvitationSend(Email).then(data => {
      if (data.success) {
        const { isInvitedView } = this.props;
        if (isInvitedView) {
          getInvitedNetworkusers().then(data => {
            if (data.success) {
              this.setState({
                networkUsers: data.items,
                filteredList: data.items,
                isInvitedView: isInvitedView,
                isFetching: false
              });
              if (data.items.length > 0) {
                this.handleItemClick(data.items[0].UserId);
              }
            }
          });
        }
        const info = {
          message: data.message,
          status: "success"
        };
        this.setState({
          networkData: {
            ...this.state.networkData,
            Email: ""
          }
        })
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error"
        };
        if (info.message.toLowerCase().includes("given email does not exist at prodoo"))
          this.handleSendSignUpInvitationClick();
        else
          this.props.notificationAction(info);
      }
    });
  }

  handleCreateNewOpportunityClick = (user) => {
    getResumeByUserId(user.UserId).then(data => {
      if (data.success) {
        let ResumeIds = [];
        ResumeIds.push(data.items.ResumeId);
        JSON.stringify(localStorage.setItem("ResumeId", data.items.ResumeId));
        const newShortlistValue = `Quick shortlist from Network for ${user.UserFirstname} ${user.UserLastname} at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
          new Date()
        ).format("LTS")}`;
        const resumeDetail = {
          ShortlistName: newShortlistValue,
          ResumeIdsList: ResumeIds
        };
        postShortlistApi(resumeDetail).then(resume => {
          let resumeShortlistId = [];
          resumeShortlistId.push(resume.items);
          this.getShortlist(user, ResumeIds, resumeShortlistId);
        }).catch(err => {
          this.props.notificationAction(err);
        });
      }
    });
  }

  getShortlist(user, ResumeIds, resumeShortlistId) {
    let loggedUser = JSON.parse(localStorage.getItem("User"));
    getNetworkUserCompanyApi(loggedUser.UserId)
      .then(company => {
        let companies = company.items;
        let companyList = [];
        companyList = companies.Companies?.find(item => item.IsDefaultCompany);
        if (companyList == null || companyList == undefined) {
          if (companies.Companies.length > 0) {
            companyList = companies.Companies[0];
          } else {
            const info = {
              message: this.props.labels.NETWORK_NO_COMPANY_MESSAGE,
              status: "error"
            };
            this.props.notificationAction(info);
            return;
          }
        }
        const opportunity = {
          RequestName: `Quick Opportunity from Network for ${user.UserFirstname} ${user.UserLastname} at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(new Date()).format("LTS")}`,
          ShortListIds: resumeShortlistId,
          UserCompanyId: companyList.UserCompanyId,
          IsSent: true,
        };
        sendOpportunityApi({ opportunity })
          .then(data => {
            this.getOpportunity(ResumeIds, user);
          })
          .catch(err => {
            const info = {
              message: this.props.labels.NETWORK_OPPORTUNITY_ERROR_MESSAGE,
              status: "error"
            };
            this.props.notificationAction(info);
          });
      })
      .catch(err => {
        const info = {
          message: this.props.labels.NETWORK_COMPANY_ERROR_MESSAGE,
          status: "error"
        };
        this.props.notificationAction(info);
      });
  }

  getOpportunity = (ResumeIds, user) => {
    getOpportunity().then(data => {
      if (data.success) {
        JSON.stringify(localStorage.setItem("OpportunityId", data.items.RequestId));
        getUserDetailApi(user.UserId)
          .then(UserDetail => {
            if (!UserDetail.items) {
              const info = {
                message: this.props.labels.presentationNotFound,
                status: "error"
              };
              this.props.notificationAction(info);
              return;
            }
            else {
              const acceptOpportunity = {
                RequestId: data.items.RequestId,
                ResumeId: ResumeIds[0],
                UserDetailId: UserDetail.items.UserDetailId,
              };
              acceptOpportunityApiThroughNetwork(acceptOpportunity)
                .then(res => {
                  this.props.history.push(privateRoutes.searcherCreateCollaboration.path);
                })
                .catch(err => {
                  this.props.notificationAction(err);
                });
            }
          })
          .catch(err => {
            this.props.notificationAction(err);
          });
      }
    })
      .catch(err => {
        this.props.notificationAction(err);
      });
  }

  handleCreateNewShortlistClick = (user) => {
    getResumeByUserId(user.UserId).then(data => {
      if (data.success) {
        let ResumeIds = [];
        ResumeIds.push(data.items.ResumeId);
        const newShortlistValue = `Quick shortlist from Network for ${user.UserFirstname} ${user.UserLastname} at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
          new Date()
        ).format("LTS")}`;
        const resumeDetail = {
          ShortlistName: newShortlistValue,
          ResumeIdsList: ResumeIds
        };
        postShortlistApi(resumeDetail).then(resume => {
          sessionStorage.setItem("opportunity-new", true);
          this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
        }).catch(err => {
          this.props.notificationAction(err);
        });
      }
    });
  };

  render() {
    const {
      selectedUser,
      isFetching,
      companyList,
      currentPresent,
      Profiles,
      Emails,
      isCreateNew,
      filteredList,
      listCollapsed,
      detailCollapsed,
      searchKey,
      networkData,
    } = this.state;
    const { labels, isHelpActive, BtnAccept, BtnDecline, BtnAddOpportunity, BtnAddCollaboration } = this.props;
    const getTabs = () => {

      return [
        {
          id: 1,
          label: labels?.CONNECTED_NETWORK_LABEL,
          active: window?.location?.hash == "#/connected-networks",
          value: "/connected-networks"
        },
        {
          id: 2,
          label: labels?.INVITED_NETWORK_LABEL,
          active: window?.location?.hash == "#/invited-networks",
          value: "/invited-networks"
        },
        {
          id: 4,
          label: labels?.INVITATIONS_NETWORK_LABEL,
          active: window?.location?.hash == "#/invitations-networks",
          value: "/invitations-networks"
        },
      ]
    }
    return (
      <PageWrapper className="network-page main-network-container">
        <div className="main">
          <div className="tabs-header-col">

            <div className="d-flex gap-16 align-items-center">
              <div className="d-flex gap-16 align-items-center main-header">

                {this?.state?.windowWidth < 767 ?
                  <Select
                    className="dropdown-callooration"
                    size="medium"
                    bordered={false}
                    onChange={(e) => {
                      this.setState(st => ({ ...st, active: e }))
                      this.props.history.push(`${e}`)
                    }}
                    value={this?.state?.active}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    options={getTabs() || []}
                  />
                  :
                  <>
                    {getTabs()?.map(single =>
                      <button className="tab-btn-coll"
                        key={single?.id}
                        style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                        onClick={() => {
                          this.props.history.push(single?.value)
                        }}
                      >
                        {single?.label}
                      </button>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
          <div className="column-section">
            <Column collapse={listCollapsed} className="col-1">
              <Column.Collapsed
                text={labels.NETWORK_USERS_LABEL}
                onClick={() =>
                  this.setState({ listCollapsed: false })
                }
                tooltipButton={labels.ToolTipFeedBackExpandList}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HlpTooltipFeedbackExpandListButton}
                tooltipPlace="left"
              />
              <Column.Head>
                <input
                  className="round-search"
                  type="text"
                  onChange={this.handleSearchChange}
                  placeholder={labels.SEARCH_USER_LABEL}
                  value={searchKey}
                />
                <Button
                  className="AddBtn"
                  onClick={this.handleNewNetwork}
                  tooltipPlace="left"
                  testId="create-shortlist-btn"
                  tooltipButton={labels.ToolTipNetworkAddBtn}
                  isHelpActive={isHelpActive}
                  tooltipHelp={labels.ToolTipNetworkAddBtn}
                />
                <Button
                  onClick={() =>
                    this.setState({ listCollapsed: true })
                  }
                  className="collapseBtn"
                  tooltipButton={labels.ToolTipFeedBackCollaspeList}
                  tooltipHelp={labels.HlpTooltipFeedbackCollapseListButton}
                  tooltipPlace="left"
                  isHelpActive={isHelpActive}
                />
              </Column.Head>
              <Column.Body>
                <NetworkList
                  UsersList={filteredList}
                  handleNetworkClick={this.handleItemClick}
                  handleDeleteNetwork={this.handleDeleteNetwork}
                  isInvitedView={this.state.isInvitedView}
                  selectedUser={selectedUser}
                  labels={this.props?.labels}
                />
              </Column.Body>
            </Column>
            {!isCreateNew && (
              <Column collapse={detailCollapsed} className="col-2">
                <Column.Collapsed
                  text={labels.USER_DETAILS_LABEL}
                  onClick={() =>
                    this.setState({ detailCollapsed: false })
                  }
                  isHelpActive={isHelpActive}
                  tooltipButton={labels.ToolTipFeedBackExpandDetail}
                  tooltipHelp={labels.HlpTooltipFeedbackExpandDetailButton}
                  tooltipPlace="left"
                />
                <Column.Head>
                  <div className="heading">
                    {labels.USER_DETAILS_LABEL}
                  </div>
                  <Button
                    className="collapseBtn"
                    onClick={() =>
                      this.setState({ detailCollapsed: true })
                    }
                    tooltipButton={labels.ToolTipFeedBackCollaspeDetail}
                    tooltipHelp={labels.HlpTooltipFeedbackCollapseDetailButton}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                  />
                </Column.Head>
                <Column.Body className="flex">
                  <NetworkDetail
                    currentPresent={currentPresent}
                    selectedUser={selectedUser}
                    Emails={Emails}
                    Profiles={Profiles}
                    companyList={companyList}
                    handleCompanyClick={this.handleCompanyClick}
                    isInvitationsView={this.state.isInvitationsView}
                    isConnectedView={this.state.isConnectedView}
                    isFetching={isFetching}
                    handleCreateNewShortlistClick={this.handleCreateNewShortlistClick}
                    handleCreateNewOpportunityClick={this.handleCreateNewOpportunityClick}
                    handleDeleteNetwork={this.handleDeleteNetwork}
                    handleAcceptNetwork={this.handleAcceptNetwork}
                    BtnAccept={BtnAccept}
                    BtnDecline={BtnDecline}
                    BtnAddOpportunity={BtnAddOpportunity}
                    BtnAddCollaboration={BtnAddCollaboration}
                  />
                </Column.Body>
              </Column>
            )}
            {isCreateNew && (
              <Column collapse={detailCollapsed} className="col-2">
                <Column.Collapsed
                  text={labels.USER_DETAILS_LABEL}
                  onClick={() =>
                    this.setState({ detailCollapsed: false })
                  }
                  isHelpActive={isHelpActive}
                  tooltipButton={labels.ToolTipFeedBackExpandDetail}
                  tooltipHelp={labels.HlpTooltipFeedbackExpandDetailButton}
                  tooltipPlace="left"
                />
                <Column.Head>
                  <div className="heading">
                    {labels.USER_DETAILS_LABEL}
                  </div>
                  <Button
                    className="SaveCloudBtn"
                    onClick={this.handleNetworkInvitationSend}
                    tooltipButton={labels.ToolTipNetworkSaveButton}
                    tooltipHelp={labels.ToolTipNetworkSaveButton}
                    isHelpActive={isHelpActive}
                    tooltipPlace="left"
                    testId="network-save-btn"
                    testIdHelp="network-save-help-btn"
                  />
                  <Button
                    className="collapseBtn"
                    onClick={() =>
                      this.setState({ detailCollapsed: true })
                    }
                    tooltipButton={labels.ToolTipFeedBackCollaspeDetail}
                    tooltipHelp={labels.HlpTooltipFeedbackCollapseDetailButton}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                  />
                </Column.Head>
                <Column.Body className="flex">
                  <NetworkForm
                    onFormFieldChange={this.handleFormFieldChange}
                    networkData={networkData}
                    labels={labels}
                  />
                </Column.Body>
              </Column>
            )}
          </div>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, feedback }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...feedback };
};

const mapActionToProps = {
  onStateChangeAction,
  notificationAction
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(withRouter(Network));
