import React, { Component } from "react";
import { EmptyInfo } from "../../../../../common";
import moment from "moment";
import "./NewOpportunityDetail.scss";
import { Col, Row, Typography } from "antd";
import { formatDate } from "../../../../../utilities";
const { Title, Text } = Typography
class NewOpportunityDetailIpro extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedOpportunity,
      emptyOpportunityInfo,
      selectInterest,
      onOpportunityRestore,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      SearcherOpportunitySentDetailFee,
      companyLabel,
      descriptionLabel,
      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      handleOpportunityDetailAcceptClick,
      handleOpportunityDetailDeclinedClick,
      handleOpportunityDetailCompanyClick,
    } = this.props;
    const retrieveSearch = selectedOpportunity.retrieveResumeSearch;
    return (
      <>
        <div className="new-opportunity-detail-ipro">
          {!selectedOpportunity.RequestId ? (
            <EmptyInfo>{emptyOpportunityInfo}</EmptyInfo>
          ) : (
            <div className="inner-user">
              <div className="banner-invite">
                {selectedOpportunity?.IsConfirmed || selectedOpportunity?.IsDeclined ?
                  <div className=" d-flex justify-content-between align-items-center" style={{ flexWrap: "wrap" }}>
                    <Title level={5} className="mb-0" style={{ color: "#04CAA7" }}>Invited By {selectedOpportunity?.UserFirstname} {selectedOpportunity?.Company?.CompanyName || ""}</Title>
                    {selectedOpportunity?.IsDeclined &&
                      <p className="clr-label fnt-s-14" style={{ color: "#8E81F5" }}>Ended on {formatDate(selectedOpportunity?.UpdatedOn)}</p>
                    }
                  </div>
                  :
                  <Title level={5} className="mb-0" style={{ color: "#04CAA7" }}>{selectedOpportunity?.UserFirstname}  {"from"} {selectedOpportunity?.Company?.CompanyName || ""}</Title>
                }
                {(!selectedOpportunity?.IsDeclined && !selectedOpportunity?.IsConfirmed) &&
                  <Text className="title mt-2">{Lbltitle}</Text>
                }
              </div>
              <div className="banner-details">
                <Row gutter={[12, 12]}>
                  <Col xs={12} md={6}>
                    <p className="clr-label fnt-s-14">{locationsLabel}</p>
                    <p className="clr-desc fnt-s-16">{selectedOpportunity?.Countries?.[0]?.CountryName || "N/A"}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="clr-label fnt-s-14">{startDateLabel}</p>
                    <p className="clr-desc fnt-s-16">{formatDate(selectedOpportunity?.StartDate) || "N/A"}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="clr-label fnt-s-14">{durationLabel}</p>
                    <p className="clr-desc fnt-s-16"> {`${selectedOpportunity?.Duration || "N/A"} ${selectedOpportunity?.DurationType || ""}` || "N/A"}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="clr-label fnt-s-14">{companyLabel}</p>
                    <p className="clr-desc fnt-s-16" onClick={handleOpportunityDetailCompanyClick}> {selectedOpportunity?.Company?.CompanyName || "N/A"}</p>
                  </Col>
                  <Col xs={24}>
                    <p className="clr-label fnt-s-14">{descriptionLabel}</p>
                    <p className="clr-desc fnt-s-16" > {selectedOpportunity?.Description || "N/A"}</p>
                  </Col>
                </Row>
              </div>
              {/* 
              {selectedOpportunity.HourlyFee && (
                <Row
                  gutter={[8, 12]}
                  style={{ marginTop: "10px", flexFlow: "unset" }}
                >
                  <Col flex="150px">
                    <p className="title">{SearcherOpportunitySentDetailFee}:</p>
                  </Col>
                  <Col flex="auto">
                    <p className="title">
                      {selectedOpportunity.HourlyFee} &nbsp;
                      {selectedOpportunity.FeeCurrencyType}
                    </p>
                  </Col>
                </Row>
              )} */}
              {retrieveSearch && (
                <div className="invSelScore">
                  {SearchParameterHeading && (
                    <Row
                      gutter={[8, 12]}
                      style={{ marginTop: "10px", flexFlow: "unset" }}
                    >
                      <Col flex="auto">
                        <p className="title" style={{ fontWeight: 600 }}>
                          {SearchParameterHeading}:
                        </p>
                      </Col>
                    </Row>
                  )}
                  <Row
                    gutter={[12, 12]}
                    style={{ marginTop: "10px" }}
                  >
                    {retrieveSearch?.Profiles?.length > 0 && (
                      <Col xs={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblProfile}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Profiles?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>

                    )}
                    {retrieveSearch?.Skills?.length > 0 && (

                      <Col xs={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblSkills}</p>
                        <div className="skills-btn">
                          {retrieveSearch?.Skills?.map((searchItem, index) =>
                            <p className="clr-desc fnt-s-14 skill-tag" key={index}>{searchItem?.SearchedValue}
                            </p>
                          )}
                        </div>
                      </Col>
                    )}
                    {retrieveSearch.Keywords?.length > 0 && (
                      <Col xs={24} md={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblKeyword}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Keywords?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>
                    )}
                    {retrieveSearch.Industeries?.length > 0 && (

                      <Col xs={24} md={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblIndustry}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Industeries?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>
                    )}
                    {retrieveSearch.Certifications?.length > 0 && (
                      <Col xs={24} md={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblCertification}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Certifications?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>
                    )}
                    {retrieveSearch.Countries?.length > 0 && (
                      <Col xs={24} md={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblCountry}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Countries?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>
                    )}
                    {retrieveSearch.Languages?.length > 0 && (
                      <Col xs={24} md={24}>
                        <p className="clr-label fnt-s-14 mb-1">{LblLanguage}</p>
                        <p className="clr-desc fnt-s-16">{retrieveSearch?.Languages?.map(searchItem => searchItem?.SearchedValue)?.join(', ')}
                        </p>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </div>
          )}
        </div>
        {selectedOpportunity.RequestId &&
          <div className="btn-footer-opp">
            {!selectedOpportunity.IsConfirmed &&
              !selectedOpportunity.IsDeleted &&
              !selectedOpportunity.IsDeclined && (
                <>
                  <button
                    className="btn-delete clr-red"
                    onClick={() => handleOpportunityDetailDeclinedClick()}
                  >
                    {BtnDecline}
                  </button>
                  <button
                    className="btn-delete clr-purple "
                    onClick={() => handleOpportunityDetailAcceptClick()}
                  >
                    {BtnAccept}
                  </button>
                </>
              )}
            {selectedOpportunity.IsDeclined && !selectedOpportunity.IsDeleted && (
              <button
                className="btn-delete clr-purple "
                onClick={() => onOpportunityRestore()}
              >
                {BtnRestore}
              </button>
            )}
          </div>
        }
      </>
    );
  }
}

export default NewOpportunityDetailIpro;
