import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getAllAcceptedCollaborations = () => {
  return axios
    .get(ApiUrl.Snapshot.GetAllAcceptedCollaborations)
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetAcceptedCollaborationCount=(token)=>
{
  return axios
    .get(ApiUrl.Snapshot.GetAcceptedCollaborationCount(token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const getAllTimeSheetsApi = (statusId, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetAllTimeSheets(statusId, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetWeekAllTimeLogs = (collaborationId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Tictell.GetWeekAllTimeLogs(collaborationId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getAllExpenseSheetsApi = (statusId, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetAllExpenseSheets(statusId, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetWeekAllExpenseLogs = (collaborationId,startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetWeekAllExpenseLogs(collaborationId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const UpdateTimeSheetStatusApi = (id,statusId,TimelogIds, token) => {
  return axios
    .get(ApiUrl.Snapshot.UpdateTimeSheetStatus(id,statusId,TimelogIds, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const UpdateExpenseSheetStatusApi = (id,statusId,ExpenseIds, token) => {
  return axios
    .get(ApiUrl.Snapshot.UpdateExpenseSheetStatus(id,statusId, ExpenseIds,token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getAllCollaborationsApi = (statusId, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetAllCollaborations(statusId, token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const DownloadColllabTimeLogReport = (statusId, token) => {
  return axios
    .get(ApiUrl.Snapshot.DownloadCollaborationReport(statusId, token),{responseType: 'blob'})
    .then(({ data }) => data)
    .catch(response => response);
};

export const DownloadPoolTimeLogReport = (statusId, token) => {
  return axios
    .get(ApiUrl.Snapshot.DownloadPoolReport(statusId, token),{responseType: 'blob'})
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetAllTimeSheetCount=(token)=>{
  return axios
    .get(ApiUrl.Snapshot.GetAllTimeSheetCount(token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetSearcherAllExpenseSheetsCount=(token)=>{
  return axios
    .get(ApiUrl.Snapshot.GetSearcherAllExpenseSheetsCount(token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetAllActiveCollaborationsCount=(token)=>{
  return axios
    .get(ApiUrl.Snapshot.GetAllActiveCollaborationsCount(token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetSharedCollaborationInfo = (collaborationId,token) => {
  return axios
    .get(ApiUrl.Snapshot.GetSharedCollaborationInfo(collaborationId, token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetUserDetailByResumeAndRequestId = (ResumeId,RequestId) => {
  return axios
    .get(ApiUrl.Snapshot.GetUserDetailByResumeAndRequestId(ResumeId, RequestId))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetResumesByResumeId = (ResumeId) => {
  return axios
    .get(ApiUrl.Snapshot.GetResumesByResumeId(ResumeId))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetmyCollaborationsAsOwnerCount = () => {
  return axios
    .get(ApiUrl.Snapshot.GetCollaborationsAsOwnerCount)
    .then(({ data }) => {
      return data;
    });
};
export const ShareCollaborationApi = (collaborationId,email,token) => {
  return axios
    .get(ApiUrl.Snapshot.ShareCollaboration(collaborationId,email,token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const DeleteShareCollaborationApi = (collaborationId,prodooId,token) => {
  return axios
    .get(ApiUrl.Snapshot.DeleteShareCollaboration(collaborationId,prodooId,token))
    .then(({ data }) => data)
    .catch(response => response);
};


export const getChildFeatureByName = ({featureName}) => {
  return axios
    .get(ApiUrl.SideNavigation.GetChildFeaturesByName({featureName}))
    .then(({ data }) => data);
};
export const GetCollaborationTimeDetailApi = (collaborationId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetCollaborationTimeDetail(collaborationId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetCollaborationExpenseDetailApi = (collaborationId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetCollaborationExpenseDetail(collaborationId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetPoolsApi = (token) => {
  return axios
    .get(ApiUrl.Snapshot.GetPools(token))
    .then(({ data }) => data)
    .catch(response => response);
}; 

export const GetPoolCollaborationsApi = (poolId,token) => {
  return axios
    .get(ApiUrl.Snapshot.GetPoolCollaborations(poolId,token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetPoolCollaborationEarningsApi = (poolId,token) => {
  return axios
    .get(ApiUrl.Snapshot.GetPoolCollaborationEarnings(poolId,token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const DeletePoolApi = (poolId,token) => {
  return axios
    .delete(ApiUrl.Snapshot.DeletePool(poolId,token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const AddPoolApi = (token,pool) => {
  return axios
    .post(ApiUrl.Snapshot.AddPool(token),pool)
    .then(({ data }) => data)
    .catch(response => response);
}; 

export const UpdatePoolApi = (token,pool) => {
  return axios
    .post(ApiUrl.Snapshot.UpdatePool(token),pool)
    .then(({ data }) => data)
    .catch(response => response);
}; 

export const GetPoolTimeDetailApi = (PoolId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetPoolTimeDetail(PoolId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetPoolExpenseDetailApi = (PoolId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Snapshot.GetPoolExpenseDetail(PoolId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const UpdateCollaborationEndDate = (collaborationId, endDate) => {
  return axios
    .get(ApiUrl.Snapshot.UpdateCollaborationEndDate(collaborationId, endDate))
    .then(({ data }) => data)
    .catch(response => response);
};
export const UpdateCollaborationHourlyRate = (collaborationId,HourlyRate, HourlyRateType) => {
  return axios
    .get(ApiUrl.Snapshot.UpdateCollaborationHourlyRate(collaborationId, HourlyRate,HourlyRateType))
    .then(({ data }) => data)
    .catch(response => response);
};

export const GetSharedPoolInfo = (poolId,token) => {
  return axios
    .get(ApiUrl.Snapshot.GetSharedPoolInfo(poolId, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const SharePoolApi = (poolId,email,token) => {
  return axios
    .get(ApiUrl.Snapshot.SharePool(poolId,email,token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const DeleteSharePoolApi = (poolId,prodooId,token) => {
  return axios
    .get(ApiUrl.Snapshot.DeleteShareCollaboration(poolId,prodooId,token))
    .then(({ data }) => data)
    .catch(response => response);
};
