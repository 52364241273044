import React, { Component } from "react";
import "./presentformeditor.scss";
import { EmptyInfo, LoadingMask } from "../../../common";
import ImgSrc from "../../../assets/images/company.svg";
import { Button, Col, Divider, Input, Row, Select, Space, Typography, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddNewPhoneNumber from "../../Company/components/addNewNumber";
// import Select from "react-select";

const { TextArea } = Input;
const { Title } = Typography

class PresentationFormNew extends Component {
  state = {
    addNewNumber: false
  }
  hideAddNumberModel = () => {
    this.setState(st => ({ ...st, addNewNumber: false }))
  }
  viewRender = () => {
    const {
      isLoading,
      presentForm,
      labels,
      profiles,
      onFormFieldChange,
      userEmails,
      userPhonenumbers,
      getUserPhonenumbers,
      onFormSelectChange,
      onSelectBlur,
      onFileChange,
      getImgSrc,
      onFormFieldBlur,
      handleSave,
      handleDelete
    } = this.props;

    if (presentForm && presentForm.UserDetailId) {
      const {
        invalidTitle,
        UserName,
        invalidUserName,
        Profiles,
        invalidRole,
        UserEmails,
        WebUrl,
        invalidWebUrl,
        Detail,
        UserDetailId,
        selectedPhoneNumber,
        IsChecked,
        IsDefault,
      } = presentForm;
      const avatar = presentForm && presentForm.Logo;
      const isMobile = window?.innerWidth > 767;
      const uploadProps = {
        beforeUpload: (file) => {
          onFileChange(file)
          return false;
        },
        fileList: [],
        maxCount: 1,
      };
      return (
        <>
          <AddNewPhoneNumber open={this?.state?.addNewNumber} hideAddNumberModel={this.hideAddNumberModel}
            title="Add Phone Number"
            heading="Add your new phone number here"
            getNumber={getUserPhonenumbers}
          />
          <div className="h-100 flex flex-column w-100">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <div className="d-flex justify-content-between gap-8 align-items-center" style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}>
                <Title level={5} className="m-0 text-clamp flex-1">{presentForm?.UserDetailId != -1 ? presentForm?.Title : "Add New Presentation"}</Title>
                {(presentForm?.UserDetailId != -1 && isMobile) &&
                  < Title level={5} style={{ color: "#FF6363", cursor: "pointer" }} className="m-0"
                    onClick={() => handleDelete(presentForm?.UserDetailId)}
                  >{labels?.delete_presentation}</Title>
                }
              </div>
              <div style={{ overflow: "auto", padding: "16px" }}>
                <div className="companyBigIcon d-flex align-items-center gap-16">
                  {/* {avatar && <button className="closeBtn" onClick={getImgSrc} />} */}
                  <div className={`dashItemImg  ${avatar ? "" : "no-dp"}`}
                    style={{ margin: "unset" }}
                  >
                    <img
                      className="roundeImg"
                      src={avatar ? avatar : ImgSrc}
                      alt="company"
                    />
                  </div>
                  <Upload {...uploadProps}>
                    <Button
                      className="upload-btn-img"
                      size="middle"
                      type="text"
                    >
                      Add Image
                    </Button>
                  </Upload>
                </div>
                <div className="container-collapse mb-3">
                  <div className="new-collapse-main">
                    <p className="mb-0 fnt-s-14"> {labels?.TITLE_LABEL}</p>
                  </div>
                  <Input
                    name="Title"
                    value={presentForm?.Title ? presentForm?.Title : ""}
                    placeholder={labels?.presentTitlePlaceholder}
                    onChange={onFormFieldChange}
                    onBlur={onFormFieldChange}
                    testId="input-text-present-title"
                    maxlength={"100"}
                    test-data-id="input-text-company-name"
                    maxLength="50"
                    size="large"
                    autoComplete="off"
                    className={`bg-color-for-opportunity  ${invalidTitle ? "inValid" : ""
                      }`}
                    bordered={false}
                  />
                </div>
                <Row gutter={[12, 8]} className="container-collapse mb-3">
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="mb-0 fnt-s-14">
                          {" "}
                          {labels.USER_NAME_LABEL}
                        </p>
                      </div>
                      <Input
                        name="UserName"
                        className={`bg-color-for-opportunity ${invalidUserName ? "inValid" : ""
                          }`}
                        value={UserName ? UserName : ""}
                        placeholder={labels.presentNamePlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldChange}
                        testId="input-text-present-username"
                        maxlength={"50"}
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="mb-0 fnt-s-14"> {labels.ROLE_LABEL}</p>
                      </div>
                      <Select
                        name="Profiles"
                        className={`bg-color-for-opportunity ${invalidRole ? "inValid" : ""
                          }`}
                        value={Profiles && Profiles.ProfileValue}
                        placeholder={labels.presentPrimaryRolePlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "Profiles",
                            "ProfileValue",
                            selectedOption
                          )
                        }
                        onBlur={onSelectBlur}
                        options={profiles}
                        clearable={false}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[12, 8]} className="container-collapse mb-3">
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="mb-0 fnt-s-14"> {labels.PHONE_LABEL}</p>
                      </div>
                      <Select
                        name="UserPhonenumbers"
                        value={selectedPhoneNumber && selectedPhoneNumber}
                        placeholder={labels.presentPhoneNumberPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "UserPhonenumbers",
                            "UserPhonenumberValue",
                            selectedOption
                          )
                        }
                        autoBlur={true}
                        options={userPhonenumbers}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                              <Button type="text" icon={<PlusOutlined />} onClick={() => {
                                this.setState(st => ({
                                  ...st,
                                  addNewNumber: true
                                }))
                              }}>
                                Add Phone number
                              </Button>
                            </Space>
                          </>
                        )}
                      ></Select>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="mb-0 fnt-s-14"> {labels.EMAIL_LABEL}</p>
                      </div>
                      <Select
                        name="UserEmails"
                        value={UserEmails && UserEmails.UserEmailValue}
                        placeholder={labels.presentEmailPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "UserEmails",
                            "UserEmailValue",
                            selectedOption
                          )
                        }
                        options={userEmails}
                        clearable={false}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[12, 8]} className="container-collapse mb-3">
                  <Col xs={24} md={24}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="mb-0 fnt-s-14"> {labels.WEB_URL_LABEL}</p>
                      </div>
                      <Input
                        name="WebUrl"
                        className={`bg-color-for-opportunity ${invalidWebUrl ? "inValid" : ""
                          }`}
                        value={WebUrl ? WebUrl : ""}
                        placeholder={labels.presentURLPlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldBlur}
                        testId="input-text-present-weburl"
                        maxlength={"100"}
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="container-collapse mb-3">
                  <div className="new-collapse-main">
                    <p className="mb-0 fnt-s-14"> {labels.collDescription}</p>
                    <TextArea
                      name="Detail"
                      value={Detail ? Detail : ""}
                      placeholder={labels.companyDescriptionPlaceholder}
                      onChange={onFormFieldChange}
                      test-data-id="input-button-company-detail"
                      rows={6}
                      className="bg-color-for-opportunity"
                      bordered={false}
                    />
                  </div>
                </div>
                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <label className="companyCheckboxLabel">
                      <Input
                        type="checkbox"
                        name="IsDefault"
                        className={IsDefault ? "checked" : "input-checkbox"}
                        value={IsDefault}
                        checked={IsDefault ? IsDefault : false}
                        onChange={onFormFieldChange}
                        test-data-id="input-checkbox-company-defaultCompany"
                        disabled={IsChecked}
                      />
                      {labels.PresentationCheckboxMakeDefault}
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end gap-16" style={{ padding: "12px", borderTop: "1px solid #f4f2fe" }}>
                <button
                  className="button-footer-opportunity pl-4 pr-4"
                  onClick={handleSave}
                >
                  {labels?.save_presentation}
                </button>
              </div>
            </div>

          </div >

        </>
      );
    }
    return (
      <EmptyInfo testId="company-empty-detail">
        {labels?.Freelance_Present}
      </EmptyInfo>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="company-form-editor">
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </div>
    );
  }
}

export default PresentationFormNew;
