import React, { Component, Fragment } from "react";
import { ConfirmDialog, LoadingMask } from "../../common";
import moment from "moment";
import {
  getAcceptedcollaborationapi,
  tictellLogin,
  tictellAppLoginApi,
  getCurrenciesApi,
  getExpensesCategoriesApi,
} from "./tictellApi";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import "./tictell.scss";
import { StorageService } from "../../api";
import { privateRoutes } from "../../Routes/routing";

import TictellNavigation from "./components/Navigation/Navigation";
import TimeLogView from "./views/Timelog/TimeLogView";
import ExpenseLogView from "./views/Expense/ExpenseLogView";
import Dashboard from "./views/Dashboard/Dashboard";
import { logoutApi } from '../../components/Navigation/navigationApi';
import { getChildFeatureByName } from "../Snapshot/snapshotApi";


class Tictell extends Component {
  state = {
    UserFeatures: [],
    selectedCollaboration: {},
    startDate: moment().startOf("isoWeek"),
    endDate: moment()
      .startOf("isoWeek")
      .add(6, "day"),
    Currencies: [],
    token: "",
    dialogMessage: "",
    isLoading: true,
    isEmpty: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const firstUrl = this.state.UserFeatures.find((a) => a.Url);

    var queryString = window.location.href;
    queryString = queryString.toLowerCase().split("tictell")[1];
    if (queryString == "") {
      if (firstUrl) {
        const isApp = window.location.hash.toLowerCase().indexOf("apps") > -1;
        this.props.history.push(
          (isApp ? "/apps" : "") + "/tictell/" + firstUrl.Url
        );
      }
    }
  }
  componentDidMount() {
    this.handleSelectedDateChange(3);
    getChildFeatureByName({ featureName: 'tictell'}).then((res) => {
      this.setState({
        UserFeatures: res.items,
      });
      const { items } = res;
      const firstUrl = items.find((a) => a.Url);
      var queryString = window.location.href;
      queryString = queryString.toLowerCase().split("tictell")[1];
      if (queryString == "") {
        if (firstUrl) {
          const isApp = window.location.hash.toLowerCase().indexOf("apps") > -1;
          this.props.history.push(
            (isApp ? "/apps" : "") + "/tictell/" + firstUrl.Url
          );
        }
      }
    });
    let { UserEmail } = this.props.User;
    let Email = UserEmail;
    if (!Email) {
      const User = StorageService.getUser();
      Email = User.Email;
    }
    tictellAppLoginApi(Email)
      .then((res) => {
        if (res.success) {
          tictellLogin(Email)
            .then((res) => {
              if (res.success) {
                const { access_token } = res.items;
                this.setState({
                  token: access_token,
                  token: res.items.access_token,
                });
                this.getCurrencies(access_token);
              }
            })
            .catch((err) => console.log("Err ", err));
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  getCurrencies(access_token) {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          this.setState({
            Currencies: res.items.map((item) => ({
              ...item,
              value: item.CurrencyId,
              label: item.Name,
            })),
          });
          this.getExpenseCategories(access_token);
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  getExpenseCategories(access_token) {
    getExpensesCategoriesApi(access_token)
      .then((res) => {
        if (res.success) {
          this.setState({
            ExpenseCategories: res.items.map((item) => ({
              ...item,
              value: item.ExpenseCategoryId,
              label: item.Title,
            })),
          });
          this.getAcceptedcollaborations();
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  getAcceptedcollaborations = () => {
    getAcceptedcollaborationapi().then((data) => {
      if (data.success && data.items.length > 0) {
        const newList = data.items.map((item) => {
          const newItem = {
            ...item,
            value: item.CollaborationId,
            label: item.Title,
            HourlyRateType: !isNaN(item.HourlyRateType)
              ? this.state.Currencies.find(
                  (a) => a.CurrencyId == item.HourlyRateType
                ).Name
              : item.HourlyRateType,
          };
          return newItem;
        });
        this.setState({
          CollaborationList: newList,
          selectedCollaboration: newList[0],
          isLoading: false,
          isEmpty: false,
        });
      } else {
        this.setState({
          dialogMessage: this.props.labels.Collaboration_Dialog_Message,
          isLoading: false,
          isEmpty: true,
        });
      }
    });
  };
  handleCollabSelectChange = (selectedOption) => {
    this.setState({
      selectedCollaboration: selectedOption,
    });
  };
  handleSelectedDateChange = (type) => {
    let { startDate, endDate } = this.state;
    if (type == 1) {
      if (
        moment(startDate)
          .subtract(1, "days")
          .month() != moment(startDate).month()
      ) {
        this.setState({
          startDate: moment(startDate)
            .subtract(1, "days")
            .startOf("isoWeek"),
          endDate: moment(startDate)
            .subtract(1, "days")
            .endOf("month"),
        });
      } else {
        startDate = moment(startDate)
          .subtract(7, "days")
          .startOf("isoWeek");
        endDate = moment(startDate).endOf("isoWeek");

        const isMonthsSame = startDate.month() == endDate.month();
        this.setState({
          startDate: isMonthsSame
            ? startDate
            : moment(startDate)
                .add(1, "month")
                .startOf("month"),
          endDate: isMonthsSame
            ? endDate
            : moment(startDate)
                .add(1, "w")
                .subtract(1, "days"),
        });
      }
    } else if (type == 2) {
      if (
        moment(endDate)
          .add(1, "days")
          .month() != moment(endDate).month()
      ) {
        this.setState({
          startDate: moment(endDate)
            .add(1, "days")
            .startOf("month"),
          endDate: moment(endDate)
            .add(1, "days")
            .endOf("isoWeek"),
        });
      } else {
        startDate = moment(startDate)
          .add(7, "days")
          .startOf("isoWeek");
        endDate = moment(startDate).endOf("isoWeek");
        const isMonthsSame = startDate.month() == endDate.month();
        this.setState({
          startDate: isMonthsSame
            ? startDate
            : moment(startDate)
                .endOf("month")
                .startOf("isoWeek"),
          endDate: isMonthsSame ? endDate : moment(startDate).endOf("month"),
        });
      }
    } else if (type == 3) {
      startDate = moment().startOf("isoWeek");
      endDate = moment().endOf("isoWeek");
      const isMonthsSame = startDate.month() == endDate.month();
      if (
        moment()
          .startOf("isoWeek")
          .isSame(
            moment()
              .endOf("month")
              .startOf("isoWeek")
          )
      ) {
        this.setState({
          startDate: isMonthsSame
            ? moment().startOf("isoWeek")
            : moment(startDate)
                .endOf("month")
                .startOf("isoWeek"),
          endDate: isMonthsSame
            ? moment().endOf("month")
            : moment(startDate).endOf("month"),
        });
      } else
        this.setState({
          startDate: isMonthsSame
            ? moment().startOf("isoWeek")
            : moment(startDate)
                .add(1, "month")
                .startOf("month"),
          endDate: isMonthsSame
            ? moment()
                .startOf("isoWeek")
                .add(6, "day")
                .add(23, "hours")
                .add(59, "minutes")
            : moment(startDate)
                .add(1, "w")
                .subtract(1, "days")
                .add(23, "hours")
                .add(59, "minutes"),
        });
    }
  };
  handleOkClick = () => {
    const currentPath = window.location.hash.toLowerCase();
    const isIndividualComponent = currentPath.includes("/apps/tictell/home");
  
    if (isIndividualComponent) {
      logoutApi()      // For Mobile App if we have no active collaboration then we show message to user and once they click on ok then we redirect to login page.
        .then((response) => {
          if (response.success) {
            StorageService.clearAll();
            this.props.history.push("/app-login");
          } else {
            console.error("Logout failed:", response.message);
          }
        })
        .catch((error) => {
          console.error("An error occurred during logout:", error);
        });
    } else {
      this.props.history.push(privateRoutes.dashboard.path);
    }
  };
  
  handleMenuClick = (currentView) => {
    if (currentView == 1 || currentView == 2) return;
    this.setState({
      currentView,
    });
  };
  render() {
    const {
      labels,
      isHelpActive,
      hours,
      minutes,
      seconds,
      stopTimer,
      pauseTimer,
      startTimer,
    } = this.props;
    const {
      UserFeatures,
      currentView,
      dialogMessage,
      isLoading,
      token,
      CollaborationList,
      selectedCollaboration,
      startDate,
      Currencies,
      isEmpty,
      ExpenseCategories,
    } = this.state;
    const hashesList = [
      "#/apps/tictell/",
      "#/tictell/",
      "#/apps/tictell",
      "#/tictell",
    ];
    const currentViewHash =
      window.location.hash.toLowerCase().split("/")[1] == "apps"
        ? window.location.hash.toLocaleLowerCase().split("/")[3]
        : window.location.hash.toLocaleLowerCase().split("/")[2];
    return (
      <PageWrapper className={`tictell-page`}>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleOkClick}
              >
                {"Ok"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <TictellNavigation
          labels={labels}
          disabled={`${isEmpty ? "disabled" : ""}`}
          handleMenuClick={this.handleMenuClick}
          isHelpActive={isHelpActive}
          UserFeatures={UserFeatures}
        />
        {!isLoading && (
          <div
            style={isEmpty ? { pointerEvents: "none", flex: 1 } : { flex: 1 }}
            className={isEmpty ? "disabled " : ""}
          >
            {currentViewHash == "home" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <Dashboard
                  token={token}
                  collaborationsList={CollaborationList}
                  UserFeatures={UserFeatures}
                />
              )}

            {currentViewHash == "timesheets" &&
              UserFeatures.findIndex(
                (a) =>
                  a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
              ) > -1 && (
                <TimeLogView
                  token={token}
                  startTimer={startTimer}
                  pauseTimer={pauseTimer}
                  stopTimer={stopTimer}
                  seconds={seconds}
                  minutes={minutes}
                  hours={hours}
                  collaborationsList={CollaborationList}
                  selectedCollaboration={selectedCollaboration}
                  handleCollabSelectChange={this.handleCollabSelectChange}
                  startDate={startDate}
                  handleSelectedDateChange={this.handleSelectedDateChange}
                  labels={labels}
                  endDate={this.state.endDate}
                  Currencies={Currencies}
                />
              )}
            {UserFeatures.findIndex(
              (a) =>
                a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
            ) > -1 &&
              currentViewHash == "expensesheets" && (
                <ExpenseLogView
                  token={token}
                  collaborationsList={CollaborationList}
                  selectedCollaboration={selectedCollaboration}
                  handleCollabSelectChange={this.handleCollabSelectChange}
                  startDate={startDate}
                  handleSelectedDateChange={this.handleSelectedDateChange}
                  Currencies={Currencies}
                  ExpenseCategories={ExpenseCategories}
                  labels={labels}
                  endDate={this.state.endDate}
                />
              )}
          </div>
        )}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, feedback, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(mapStateToProps, { notificationAction })(Tictell);
