import React, { Component } from "react";
import "./companyDetail.scss";
import ImgSrc from "../../assets/images/company.svg";
import { Col, Row } from "antd";
import EmptyInfo from "../EmptyInfo/EmptyInfo";

class CompanyDetailNew extends Component {
  render() {
    const {
      selectedCompany,
      addressLabel,
      industryLabel,
      countryLabel,
      phoneLabel,
      webUrlLabel,
      vatLabel,
    } = this.props;
    return (
      <div className="new-company-detail-component">
        <div className="inner-company">
          {/* {!selectedCompany.UserCompanyId ? (
            <EmptyInfo>
              {"Select the opportunity from the list to see its details"}
            </EmptyInfo>
          ) : (
            <> */}
          <div className="image-user">
            {selectedCompany && selectedCompany?.Logo && (
              <img
                src={selectedCompany?.Logo || ImgSrc}
                className={
                  selectedCompany?.Logo ? "roundImg" : "emptyRoundImag"
                }
              />
            )}
          </div>
          {selectedCompany && selectedCompany?.CompanyName && (
            <p className="user-title"> {selectedCompany?.CompanyName}</p>
          )}

          <Row
            gutter={[16, 20]}
            className="pt-2"
          >
            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {addressLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">{selectedCompany?.CompanyAddress || "N/A"}</p>
            </Col>
            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {industryLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">{selectedCompany?.Industry?.IndustryValue || "N/A"}</p>
            </Col>
            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {countryLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">{selectedCompany?.CompanyCountry?.CountryName || "N/A"}
              </p>
            </Col>


            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {phoneLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">{selectedCompany?.UserPhonenumber?.UserPhonenumberValue || "N/A"}
              </p>
            </Col>
            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {webUrlLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">
                <a target="_blank" href={selectedCompany?.WebUrl}>
                  {selectedCompany?.WebUrl || "N/A"}
                </a>
              </p>
            </Col>
            <Col xs={24} md={12}>
              <p className="fnt-s-14 mb-1 clr-gray"> {vatLabel}</p>
              <p className="fnt-s-16 m-0 clr-black">
                {selectedCompany?.Vat || "N/A"}
              </p>
            </Col>

            <Col xs={24} md={24}>
              <p className="fnt-s-14 mb-1 clr-gray"> Details</p>
              <p className="fnt-s-16 m-0 clr-black">
                {selectedCompany?.Detail || "N/A"}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CompanyDetailNew;
