import React, { Component } from "react";
import moment from "moment";
import Slider from "rc-slider";
import './form.scss'
import { Col, DatePicker, Input, Row, Select, Space } from "antd";
import MenuDots from "../../../../../assets/newResume/menuDots";
const { TextArea } = Input;

const Handle = Slider.Handle;
class NewSearcherOpportunityForm extends Component {
  constructor(props) {
    super(props);

    this.durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" },
    ];
  }
  handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Handle value={value} key={index} {...restProps}>
        {value}
      </Handle>
    );
  };
  render() {
    const {
      state,
      selectedOpportunity,
      labels,
      handleSelectedShortlistClick,
      selectedSaveSearch,
      selectedCompany,
      userCompanies,
      savedSearches,
      shortlist,
      onFormFieldChange,
      onFormSelectChange,
      onOpportunityUpdate,
      onDateChange,
      onSliderChange,
      onLanguageSelect,
      onLocationSelect,
      onOpportunitySend,
      allLocations,
      allLanguages,
      allCurrencies,
      handleListOpenMobile,
    } = this.props;
   

    return (
      <div className=" flex flex-column opportunity-form" style={{ flex: 1, overflow: "auto" }}>
        <div
          style={{
            // overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto"
          }}
        >
          {/* <div>
            <p className="title-filter">Opportunity Details </p>
          </div> */}
          <div style={{ overflow: "auto" }} className="main-form">
            <div className="container-collapse">
              <Input
                size="large"
                type="text"
                autoComplete="off"
                name="RequestName"
                className={`bg-color-for-opportunity  ${selectedOpportunity?.invalidRequestName ? "inValid" : ""
                  }`}
                value={selectedOpportunity?.RequestName ? selectedOpportunity?.RequestName : ""}
                placeholder={
                  labels?.SearcherOpportunityDraftDetailTitlePlaceholder
                }
                onChange={onFormFieldChange}
                onBlur={onFormFieldChange}
                bordered={false}
              />
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Start Date</p>
              </div>
              <div>
                <DatePicker
                  style={{ width: "100%", marginTop: "3px" }}
                  size="large"
                  bordered={false}
                  name="StartDate"
                  dateFormat="DD-MM-YYYY"
                  selected={selectedOpportunity?.StartDate || ""}
                  value={selectedOpportunity?.StartDate && moment(selectedOpportunity?.StartDate) || ""}
                  onChange={onDateChange}
                  todayButton="Today"
                  className={`${selectedOpportunity?.invalidStartDate
                    ? "bg-color-for-opportunity inValid"
                    : "bg-color-for-opportunity"
                    }`}
                  placeholderText={
                    labels?.SearcherOpportunityDraftDetailStartDate
                  }
                  minDate={moment()}
                />
              </div>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">
                  {labels?.SearcherOpportunityDraftDetailDuration}
                </p>
              </div>
              <Row
                gutter={[12, 8]}
                style={{ marginTop: "3px", alignItems: "center" }}
              >
                <Col xs={24} md={12}>
                  <Slider
                    name="durationSlider"
                    min={0}
                    max={12}
                    defaultValue={4}
                    value={parseInt(selectedOpportunity?.Duration)}
                    handle={this.handle}
                    onAfterChange={onSliderChange}
                    onChange={onSliderChange}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Select
                    autoFocus={selectedOpportunity?.invalidDurationType}
                    name="DurationType"
                    className={`select-input bg-color-for-opportunity  `}
                    placeholder={
                      labels?.SearcherOpportunityDraftDetailDurationPlaceholder
                    }
                    value={selectedOpportunity?.DurationType}
                    onChange={(selectedOption) =>
                      onFormSelectChange("DurationType", selectedOption)
                    }
                    options={this.durationTypeList}
                    size="large"
                    showArrow
                    bordered={false}
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  ></Select>
                </Col>
              </Row>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">
                  {labels?.SearcherOpportunityDraftDetailHourlyFee}
                </p>
              </div>
              <Row gutter={[12, 8]} style={{ marginTop: "3px" }}>
                <Col xs={24} md={12}>
                  <Input
                    size="large"
                    className={`bg-color-for-opportunity  ${state?.validation?.hourlyRate ? "inValid" : ""
                      }`}
                    bordered={false}
                    type="text"
                    autoComplete="off"
                    name="HourlyFee"
                    placeholder={
                      labels?.SearcherOpportunityDraftDetailHourlyFeePlaceholder
                    }
                    value={selectedOpportunity?.HourlyFee ? selectedOpportunity?.HourlyFee : ""}
                    onChange={onFormFieldChange}
                    onBlur={onFormFieldChange}
                    test-data-id="input-txt-opportunity-hourlyRate"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Select
                    size="large"
                    showArrow
                    className={`bg-color-for-opportunity  ${state?.validation?.feeCurrencyType ? "inValid" : ""
                      }`}
                    bordered={false}
                    style={{ width: "100%" }}
                    name="FeeCurrencyType"
                    placeholder={
                      labels?.SearcherOpportunityDraftDetailHourlyFeeDropDownPlaceholder
                    }
                    value={
                      selectedOpportunity?.FeeCurrencyType?.CurrencyId && selectedOpportunity?.FeeCurrencyType?.CurrencyId
                    }
                    onChange={(e, selectedOption) =>
                      onFormSelectChange("FeeCurrencyType", selectedOption)
                    }
                    options={allCurrencies}
                  ></Select>
                </Col>
              </Row>
            </div>

            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Shortlist</p>
              </div>
              <Row style={{ alignItems: "center", marginTop: "3px" }}>
                <Col xs={24}>
                  <div>
                    <Select
                      size="large"
                      className={`bg-color-for-opportunity  ${selectedOpportunity?.invalidShortList ? "inValid" : ""
                        }`}
                      bordered={false}
                      style={{ width: "100%" }}
                      value={selectedOpportunity?.selectedShortlists && selectedOpportunity?.selectedShortlists}
                      placeholder="Search and add your shortlist"
                      onChange={(e, option) =>
                        handleSelectedShortlistClick(option)
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={shortlist || []}
                    />
                  </div>
                </Col>
                {/* <Col md={0} xs={2} onClick={() => handleListOpenMobile()}>
                  <MenuDots style={{ float: "right" }} />
                </Col> */}
              </Row>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Saved Search</p>
              </div>
              <div>
                <Select
                  size="large"
                  style={{ width: "100%", marginTop: "3px" }}
                  className="bg-color-for-opportunity"
                  bordered={false}
                  placeholder={
                    labels?.SearcherOpportunityDraftDetailSavedSearchPlaceholder
                  }
                  name="SelectedSavedSearch"
                  value={selectedSaveSearch?.value}
                  onChange={(e, selectedOption) =>
                    onFormSelectChange("selectedSavedSearch", selectedOption)
                  }
                  options={savedSearches}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                ></Select>
              </div>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Workplace</p>
              </div>
              <div>
                <Select
                  size="large"
                  showArrow
                  className="bg-color-for-opportunity"
                  bordered={false}
                  style={{ width: "100%", marginTop: "3px" }}
                  value={selectedCompany && selectedCompany}
                  placeholder={
                    labels?.SearcherOpportunityDraftDetailCompanyPlaceholder
                  }
                  onChange={(e, selectedOption) =>
                    onFormSelectChange("SelectedCompany", selectedOption)
                  }
                  options={userCompanies}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                ></Select>
              </div>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Location</p>
              </div>
              <div>
                <Select
                  size="large"
                  style={{ width: "100%", marginTop: "3px" }}
                  placeholder="Select Location"
                  onChange={(e, option) =>
                    onLocationSelect(e, "Languages", option)
                  }
                  showSearch
                  showArrow
                  className="bg-color-for-opportunity"
                  bordered={false}
                  value={selectedOpportunity?.selectedLocation || []}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  options={allLocations}
                ></Select>
              </div>
            </div>
            <div className="container-collapse">
              <div className="new-collapse-main">
                <p className="collapse-title">Language</p>
              </div>
              <div>
                <Select
                  size="large"
                  style={{ width: "100%", marginTop: "3px" }}
                  onChange={(e, option) =>
                    onLanguageSelect(e, "Languages", option)
                  }
                  showSearch
                  showArrow
                  placeholder={
                    labels?.SearcherOpportunityDraftDetailLanguagePlaceholder
                  }
                  options={allLanguages}
                  className="bg-color-for-opportunity"
                  bordered={false}
                  value={selectedOpportunity?.selectedLanguages || []}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                ></Select>
              </div>
            </div>

            <div className="container-collapse">
              <div className="new-collapse-main">
                <TextArea
                  rows={6}
                  name="Description"
                  placeholder={labels?.collDescription}
                  value={selectedOpportunity?.Description || ""}
                  onChange={onFormFieldChange}
                  className="bg-color-for-opportunity"
                  bordered={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-send-opportunutiy" style={{ borderTop: "1px solid #F4F2FE" }}>
          <Row className="w-100" gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 8]}>
            <Col xs={24} sm={12} md={24}>
              <Space
                size={[8, 8]}
                style={{
                  justifyContent: "end",
                  display: "flex",
                  width: "100%",
                }}
              >
                <button
                  className="button-last-footer pl-4 pr-4"
                  onClick={onOpportunityUpdate}
                >
                  {labels?.save_draft_oppportunity}
                </button>
                <button
                  className="button-footer-opportunity pl-4 pr-4"
                  onClick={onOpportunitySend}
                >
                  {labels?.send_opportunity}
                </button>

              </Space>
            </Col>
            <Col xs={24} sm={12} md={0}>
              <div className="tabs-header-col my-0 h-100 w-100">
                <button className="tab-btn-coll h-100 w-100"
                  style={{ background: "#6C63FF", color: "#fff" }}
                  onClick={() => { handleListOpenMobile() }}
                >
                  {labels?.view_selected_resume}
                </button>
              </div>
            </Col>
          </Row>
        </div>
        {/* <Row className="">
          <Space
            size={[8, 8]}
            style={{
              justifyContent: "end",
              display: "flex",
              padding: "12px",
              width: "100%",
              borderTop: "1px solid #F4F2FE"
            }}
          >
            <button
              className="button-last-footer pl-4 pr-4"
              onClick={onOpportunityUpdate}
            >
              {labels?.save_draft_oppportunity}
            </button>
            <button
              className="button-footer-opportunity pl-4 pr-4"
              onClick={onOpportunitySend}
            >
              {labels?.send_opportunity}
            </button>

          </Space>
        </Row> */}
      </div>
    );
  }
}

export default NewSearcherOpportunityForm;
