import React, { Component } from "react";
import { connect } from "react-redux";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../../components";
import { notificationAction } from "../../../../actions";
import { formatDate, Messages } from "../../../../utilities";
import toLower from "lodash/toLower";
import { LoadingMask, ConfirmDialog, EmptyInfo } from "../../../../common";
import "./opportunity.scss";
import {
  getAllOpportunityApi,
  retrieveResumeSearchApi,
  declinedOpportunityApi,
  deleteOpportunityApi,
  getSocialMediaApi,
  opportunityRestoreApi,
  getAllPresenationApi,
  readOpportunityApi,
  getCurrenciesApi,
} from "./opportunityApi";
import { unreadOpportunitiesAction } from "../../../../actions/navigationActions";
import { onStateChangeAction } from "./opportunityAction";
import { Col, Input, Row, Select, Space, Typography } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";
import NewOpportunityDetailIpro from "./components/NewOpportunityDetail";
import CompanyDetailNew from "../../../../common/companyDetail/companyDetail";
import PresentationView from "./components/presentationView";
import CompanyDetailMobileModal from "./components/companyDetailMobile";
import PresentationMobileModal from "./components/presentationViewMobile";
import ArrowLeft from "../../../../assets/images/Icons/arrow-left.png";
import SearchIcon from '../../../../assets/images/secrch-icon.png'
import moment from "moment";
import { withRouter } from "react-router-dom";

const { Option } = Select;
const { Title, Text } = Typography

class OpportunityNew extends Component {
  state = {
    opportunity: [],
    selected: null,
    active: "",
    loading: false,
    companyModal: false,
    windowWidth: window.innerWidth,
  };
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      opportunityList: [],
      filteredOpportunity: [],
      fetchingOpportunity: false,
      showDetail: false,
      deletedId: null,
      requestName: "",
      selectedOpportunity: {},
      selectedCompany: {},
      showPresentationDetail: false,
    });
    const { isAcceptedView, isDeclinedView } = this.props;
    window.actionName = isAcceptedView
      ? "accepted"
      : isDeclinedView
        ? "declined"
        : "new";
    this.props.onStateChangeAction({
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
    this.getSocialMedia();
    this.getAllOpportunity();
    window.addEventListener("resize", this.handleResize);
    this.setState(st => ({ ...st, active: window?.location?.hash?.slice(1) }))
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getSocialMedia = () => {
    getSocialMediaApi().then((data) => {
      if (data.success) {
        this.props.onStateChangeAction({ socialMedia: data.items });
      }
    });
  };

  getAllOpportunity = () => {
    this.setState(st => ({ ...st, showDetail: false }))
    this.props.onStateChangeAction({ isLoading: true });
    const { url, isAccepted, opportunityType, currencies } = this.props;

    const loadCurrencies = new Promise((resolve) => {
      if (currencies && currencies.length > 0) {
        resolve(currencies);
      } else {
        getCurrenciesApi()
          .then((res) => {
            if (res.success) {
              resolve(res.items);
            } else {
              resolve([]);
            }
          })
          .catch((err) => {
            console.log("Err ", err);
            resolve([]);
          });
      }
    });

    loadCurrencies.then(currenciesList => {
      this.props.onStateChangeAction({ fetchingOpportunity: true });
      getAllOpportunityApi({ url })
        .then((data) => {
          let allOpportunity = [];
          if (data.success) {
            allOpportunity = map(data.items[opportunityType], (opp) => {
              let currencyName = opp.FeeCurrencyType;

              if (currenciesList && currenciesList.length > 0) {
                const currencyObj = currenciesList.find(
                  curr => Number(curr.CurrencyId) === Number(opp.FeeCurrencyType)
                );
                if (currencyObj) {
                  currencyName = currencyObj.Name;
                }
              }

              return {
                ...opp,
                FeeCurrencyType: currencyName,
                label: opp?.RequestName,
                value: opp?.RequestResumeId,
              };
            });

            this.props.onStateChangeAction({
              opportunityList: allOpportunity,
              filteredOpportunity: allOpportunity,
              fetchingOpportunity: false,
              deletedId: null,
              requestName: "",
              selectedOpportunity: {},
              showPresentationDetail: false,
            });

            // this.handleOpportunitySelect(allOpportunity?.[0]);
            if (
              opportunityType === "NewRequests" &&
              data.items.NewRequests.length > 0
            ) {
              const spliturl = this.props.locationProp.pathname.match(/\d+/g);
              if (spliturl != null) {
                let filterrequestid = data.items.NewRequests.filter(
                  (opportunity) => opportunity.RequestId == spliturl[0]
                );
                this.setState({
                  opportunity: filterrequestid[0],
                });
                // this.handleOpportunitySelect(this.state.opportunity);
              }
            }
            if (
              this.props.locationProp.state &&
              this.props.locationProp.state.visitRequestId
            ) {
              const visitOpportunity = filter(this.props.filteredOpportunity, [
                "RequestId",
                this.props.locationProp.state.visitRequestId,
              ]);
              this.props.onStateChangeAction({
                selectedCompany: visitOpportunity[0].Company,
                selectedOpportunity: visitOpportunity[0],
              });
              this.retrieveResumeSearch(this.props.selectedOpportunity);
            }
            this.props.onStateChangeAction({ isLoading: false });
          } else {
            this.props.onStateChangeAction({
              fetchingOpportunity: false,
              isLoading: false,
            });
          }
        })
        .catch(() => {
          this.props.onStateChangeAction({ isLoading: false });
        });
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunityList } = this.props;
    const filteredOpportunity = filter(opportunityList, (opp) =>
      includes(toLower(opp.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filteredOpportunity, searchKey: value });
  };

  handleDeleteOpportunityNew = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.labels.iproInvitationDeletionConfirmation,
    });
  };

  handleYesClick = (event) => {
    const { deletedId, requestName } = this.props;
    this.props.onStateChangeAction({ dialogMessage: "" });
    this.props.onStateChangeAction({ isLoading: true });

    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = deletedId;
    obj.RequestName = requestName;
    obj.ResumeId = 0;
    deleteOpportunityApi(obj)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.iproInvitationSuccessfullyDeleted,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
            showCompanyDetails: false,
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };

  handleOpportunitySelect = (selectedOpportunity) => {
    this.setState((st) => ({ ...st, selected: selectedOpportunity?.value, showDetail: true }));
    this.props.onStateChangeAction({
      selectedOpportunity,
      selectedCompany: selectedOpportunity.Company,
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    if (!selectedOpportunity.IsRead) {
      readOpportunityApi({
        requestResumeId: selectedOpportunity.RequestResumeId,
      }).then((response) => {
        if (response.success) {
          const { opportunityList } = this.props;
          const {
            isAcceptedView,
            isDeclinedView,
            isNewOpportunityView,
          } = this.props;
          const opportunities = filter(
            opportunityList,
            (item) =>
              item.RequestId !== selectedOpportunity.RequestId &&
              item.RequestResumeId !== selectedOpportunity.RequestResumeId &&
              !item.IsRead
          );
          if (isNewOpportunityView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: { newOpportunities: opportunities.length },
            });
          } else if (isAcceptedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                acceptedOpportunities: opportunities.length,
              },
            });
          } else if (isDeclinedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                declinedOpportunities: opportunities.length,
              },
            });
          }
          const filteredOpportunity = map(opportunityList, (oppo) => {
            if (oppo.RequestId === selectedOpportunity.RequestId) {
              oppo.IsRead = true;
              return oppo;
            }
            return oppo;
          });
          this.props.onStateChangeAction({ filteredOpportunity });
        }
      });
    }

    if (this.props.isAccepted && selectedOpportunity.SavedSearchedId) {
      //find
      this.retrieveResumeSearch(selectedOpportunity);
    }
  };

  retrieveResumeSearch = (selectedOpportunity) => {
    retrieveResumeSearchApi(selectedOpportunity.SavedSearchedId).then(
      (data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...selectedOpportunity,
              retrieveResumeSearch: {
                Profiles: data.items.filter((a) => a.LookupTypeId == 1),
                Skills: data.items.filter((a) => a.LookupTypeId == 2),
                Keywords: data.items.filter((a) => a.LookupTypeId == 3),
                Industeries: data.items.filter((a) => a.LookupTypeId == 4),
                Certifications: data.items.filter((a) => a.LookupTypeId == 5),
                Languages: data.items.filter((a) => a.LookupTypeId == 6),
                Countries: data.items.filter((a) => a.LookupTypeId == 7),
              },
            },
          });
        }
      }
    );
  };

  //Restore POPUP -start
  handleOpportunityRestore = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: this.props.labels.oppRestorationConfiramtion,
    });
  };

  restoreHandleYesClick = () => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({ isLoading: true });

    opportunityRestoreApi(selectedOpportunity.RequestResumeId)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.IproOpportunityDeclinedRestoreMessage,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            restoreDialogMessage: "",
            showPresentationDetail: false,
            showCompanyDetails: false,
            selectedOpportunity: {},
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
        this.setState((st) => ({ ...st, selected: null }));
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  restoreHandleNoClick = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: "",
      deletedId: null,
    });
  };

  //Restore POPUP -end
  handleDeleteOpportunity = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.opportunityDeletedMsg,
    });
  };

  handleOpportunityDetailAcceptClick = () => {
    this.getAllPresentation();
  };
  handleOpportunityDetailDeclinedClick = () => {
    this.setState((st) => ({ ...st, selected: null }));
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = this.props.selectedOpportunity.RequestId;
    obj.ResumeId = 0;
    obj.RequestName = this.props.selectedOpportunity.RequestName;
    this.declinedOpportunity(obj);
  };

  declinedOpportunity = (info) => {
    this.props.onStateChangeAction({ isLoading: true });
    declinedOpportunityApi(info)
      .then((data) => {
        if (data.success) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.iproOppNewDeclinedMessage,
            status: "success",
          };
          this.props.notificationAction(info);

          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
            showCompanyDetails: false,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: true });
      });
  };

  handleOpportunityDetailCompanyClick = () => {
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
  };

  getAllPresentation = () => {
    this.setState((st) => ({ ...st, loading: true }));
    getAllPresenationApi()
      .then((data) => {
        if (data.success) {
          if (data.items.length > 0) {
            this.props.onStateChangeAction({
              showPresentationDetail: true,
              showCompanyDetails: false,
              companyPresentationHeadingText: this.props.labels
                .IproOpportunityNewUserDetailHeaderText,
            });
            if (this?.state?.windowWidth < 768) {
              this.setState((st) => ({ ...st, presentaionModal: true }));
            }
          } else {
            const info = {
              message: this.props.labels
                .OPPORTUNITY_PRESENT_NOT_AVAILABLE_MESSAGE,
              status: "info",
            };
            this.props.notificationAction(info);
          }
          this.setState((st) => ({ ...st, loading: false }));
        }
      })
      .catch(() => {
        this.setState((st) => ({ ...st, loading: false }));
      });
  };

  render() {
    const {
      searchInputPlaceholder,
      emptyOpportunityInfo,
      selectInterest,
      companyAddressLabel,
      companyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      companyLabel,
      descriptionLabel,
      countryLabel,

      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      LblInterest,
      LblRestore,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      isAccepted,
      opportunityType,
      labels,
    } = this.props;
    const {
      selectedOpportunity,
      filteredOpportunity,
      isLoading,
      selectedCompany,
      socialMedia,
      showPresentationDetail,
      showCompanyDetails,
      dialogMessage,
      restoreDialogMessage,
    } = this.props;

    const getTabs = () => {

      return [
        {
          id: 1,
          label: "New",
          active: window?.location?.hash == "#/new-opportunity",
          value: "/new-opportunity"
        },
        {
          id: 2,
          label: "Active",
          active: window?.location?.hash == "#/accepted-opportunities",
          value: "/accepted-opportunities"
        },
        {
          id: 2,
          label: "Ended",
          active: window?.location?.hash == "#/declined-opportunities",
          value: "/declined-opportunities"
        },
      ]
    }

    console.log({ filteredOpportunity })

    return (
      <PageWrapper className="searcher-draft-opportunity-page search-page-new-design opportunity">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="opportunityIPro-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-yes"
                onClick={this.handleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-no"
                onClick={this.handleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {restoreDialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {restoreDialogMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        <div className="h-100 new-design-search w-100 d-flex flex-column">
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ? <div
              onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}
            >
              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
            </div> :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>

              </div>
            }
          </div>
          <Row className=" h-100 " style={{ overflow: "auto" }}>
            <Col xs={24} md={selectedOpportunity.RequestId ? 10 : 24} className="opportunity-column1">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    borderBottom: "1px solid #F3F3F3"
                  }}
                >
                  {this?.state?.showDetail ?
                    <>
                      {this?.state?.isResumeDetail ?
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, isResumeDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0 text-clamp">{labels?.opportunity_detail}</Title>

                          </div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, showDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0 text-clamp">{selectedOpportunity?.label || ""}</Title>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%"
                      }}
                    >

                      <Input
                        placeholder={searchInputPlaceholder}
                        size="medium"
                        bordered={false}
                        style={{ border: "1px solid #F3F3F3" }}
                        onChange={this.handleSearchChange}
                      />
                      <div>
                        <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                      </div>
                    </div>
                  }
                </div>
                {this?.state?.showDetail ? (
                  <>
                    {this?.state?.isResumeDetail ?
                      <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                        <NewOpportunityDetailIpro
                          selectedOpportunity={selectedOpportunity}
                          emptyOpportunityInfo={emptyOpportunityInfo}
                          locationsLabel={locationsLabel}
                          startDateLabel={startDateLabel}
                          SearcherOpportunitySentDetailFee={
                            labels.SearcherOpportunitySentDetailFee
                          }
                          durationLabel={durationLabel}
                          companyLabel={companyLabel}
                          descriptionLabel={descriptionLabel}
                          countryLabel={countryLabel}
                          SearchParameterHeading={SearchParameterHeading}
                          Lbltitle={Lbltitle}
                          LblProfile={LblProfile}
                          LblSkills={LblSkills}
                          LblKeyword={LblKeyword}
                          LblIndustry={LblIndustry}
                          LblCertification={LblCertification}
                          LblCountry={LblCountry}
                          LblInterest={LblInterest}
                          LblRestore={LblRestore}
                          BtnAccept={BtnAccept}
                          BtnDecline={BtnDecline}
                          BtnRestore={BtnRestore}
                          LblLanguage={LblLanguage}
                          selectInterest={selectInterest}
                          handleOpportunityDetailAcceptClick={
                            this.handleOpportunityDetailAcceptClick
                          }
                          handleOpportunityDetailDeclinedClick={
                            this.handleOpportunityDetailDeclinedClick
                          }
                          handleOpportunityDetailCompanyClick={
                            this.handleOpportunityDetailCompanyClick
                          }
                          IsSearcher={IsSearcher}
                          opportunityType={opportunityType}
                          showPresentationDetail={showPresentationDetail}
                          showCompanyDetails={showCompanyDetails}
                          onOpportunityRestore={this.handleOpportunityRestore}
                          isAccepted={isAccepted}
                        />
                      </div>
                      :
                      <div style={{ flex: 1, display: 'flex', flexDirection: "column", overflow: "auto" }}>
                        {selectedOpportunity?.value ? (
                          <>
                            {showCompanyDetails && selectedCompany?.UserCompanyId && (
                              <CompanyDetailNew
                                selectedCompany={selectedCompany}
                                addressLabel={companyAddressLabel}
                                industryLabel={companyIndustryLabel}
                                countryLabel={companyCountryLabel}
                                phoneLabel={companyPhoneLabel}
                                webUrlLabel={companyWebUrlLabel}
                                vatLabel={companyVatLabel}
                              />
                            )}
                            {showPresentationDetail && (
                              <PresentationView
                                selectedOpportunityNew={selectedOpportunity}
                                showCompanyDetails={showCompanyDetails}
                                showPresentationDetail={showPresentationDetail}
                                loading={this.state.loading}
                                labels={labels}
                                socialMedia={socialMedia}
                                getAllOpportunity={this.getAllOpportunity}
                                handleOk={() => { }}
                              />
                            )}
                          </>
                        ) : (
                          <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                        )}
                        {(this?.state?.windowWidth < 767) && (
                          <div style={{ margin: "auto", padding: "12px" }}>
                            <div className="tabs-header-col my-0">
                              <button className="tab-btn-coll"
                                style={{ background: "#6C63FF", color: "#fff" }}
                                onClick={() => {
                                  this.setState(st => ({
                                    ...st,
                                    isResumeDetail: true
                                  }))
                                }}
                              >
                                {labels?.opportunity_view_detail}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        overflow: "auto",
                        padding: "12px",
                        flex: 1
                      }}
                    >
                      {filteredOpportunity?.length > 0 ? (
                        <Space size={[6, 6]} wrap className="short-list-new">
                          {filteredOpportunity?.map((item) => (
                            <div
                              onClick={() => this.handleOpportunitySelect(item)}
                              key={item?.value}
                              className={`d-flex gap-8 justify-content-between align-items-center pointer ${selectedOpportunity?.value == item?.value
                                ? "background-shortlist short-list-item "
                                : "short-list-item"
                                }`}>
                              <div className="d-flex text-clamp gap-8">
                                <div className="text-clamp">
                                  <label style={{ fontSize: "14px" }}>
                                    {item.label}
                                  </label>
                                  <p style={{ fontSize: "12px", marginBottom: 0 }}>{item?.Company?.CompanyName || ""}</p>
                                </div>
                              </div>
                              {!item?.IsRead &&
                                <div style={{ textAlign: "center" }}>
                                  <label className="chip-style" style={{ fontSize: "14px" }}>
                                    New
                                  </label>
                                  <p style={{ fontSize: "12px", marginBottom: 0, marginTop: "4px" }}>{moment(item?.UpdatedOn)?.format("hh:mm A")}</p>
                                </div>
                              }
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo showBtn={true}>{labels?.empty_opportunity_message}</EmptyInfo>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col md={selectedOpportunity.RequestId ? 14 : 0} xs={0} offset={0.5}
              className={"h-100 "}
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <div className="d-flex justify-content-between align-items-center text-clamp" style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}>
                  <Title level={5} className="m-0 text-clamp" >{labels?.opportunity_detail}
                  </Title>
                  {/* <Text level={5} className="m-0 pointer" style={{ color: "#FF6363", whiteSpace: "pre" }}
                    onClick={(e) => {
                      e.stopPropagation()
                      this.handleOpportunityDelete({
                        selectedOpportunity: selectedOpportunity, e
                      });
                    }}
                  >Delete Opportunity</Text> */}
                </div>
                <NewOpportunityDetailIpro
                  selectedOpportunity={selectedOpportunity}
                  emptyOpportunityInfo={emptyOpportunityInfo}
                  locationsLabel={locationsLabel}
                  startDateLabel={startDateLabel}
                  SearcherOpportunitySentDetailFee={
                    labels.SearcherOpportunitySentDetailFee
                  }
                  durationLabel={durationLabel}
                  companyLabel={companyLabel}
                  descriptionLabel={descriptionLabel}
                  countryLabel={countryLabel}
                  SearchParameterHeading={SearchParameterHeading}
                  Lbltitle={Lbltitle}
                  LblProfile={LblProfile}
                  LblSkills={LblSkills}
                  LblKeyword={LblKeyword}
                  LblIndustry={LblIndustry}
                  LblCertification={LblCertification}
                  LblCountry={LblCountry}
                  LblInterest={LblInterest}
                  LblRestore={LblRestore}
                  BtnAccept={BtnAccept}
                  BtnDecline={BtnDecline}
                  BtnRestore={BtnRestore}
                  LblLanguage={LblLanguage}
                  selectInterest={selectInterest}
                  handleOpportunityDetailAcceptClick={
                    this.handleOpportunityDetailAcceptClick
                  }
                  handleOpportunityDetailDeclinedClick={
                    this.handleOpportunityDetailDeclinedClick
                  }
                  handleOpportunityDetailCompanyClick={
                    this.handleOpportunityDetailCompanyClick
                  }
                  IsSearcher={IsSearcher}
                  opportunityType={opportunityType}
                  showPresentationDetail={showPresentationDetail}
                  showCompanyDetails={showCompanyDetails}
                  onOpportunityRestore={this.handleOpportunityRestore}
                  isAccepted={isAccepted}
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}

//export default Opportunity;
const mapStateToProps = (
  {
    systemLabel,
    iproOpportunityNew,
    iproOpportunityAccepted,
    iproOpportunityDeclined,
    currency,
  },
  { isAcceptedView, isDeclinedView }
) => {
  const { labels } = systemLabel;
  const { currencies } = currency;
  const opportunityProps = isAcceptedView
    ? iproOpportunityAccepted
    : isDeclinedView
      ? iproOpportunityDeclined
      : iproOpportunityNew;
  return { labels, currencies, ...opportunityProps };
};

export default connect(mapStateToProps, {
  notificationAction,
  unreadOpportunitiesAction,
  onStateChangeAction,
})(withRouter(OpportunityNew));

