import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  HashRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { RESET_LANDINGPAGE_URL } from "../utilities/enviroments";
import includes from "lodash/includes";
import { ViewWrapper, AppViewWrapper, SearcherPhillip } from "../components";
import { roles } from "../utilities";
import { appMetaData } from "../utilities/metaData";
import { logoutAction } from "../store/initialConfig";
import { Cookie } from "../common";
import { useStopwatch } from "react-timer-hook";

import {
  // privateRoutesArray,
  // publicRoutesArray,
  privateRoutes,
  publicRoutes,
  appsRoutes,
} from "./routing";
import { logoutApi } from "../components/Navigation/navigationApi";
import { StorageService } from "../api";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { MobileLogin } from "../views/LeadPage/components";
import { oldRoutes } from "./OldRoutesUrl";
import { onStateChangeActions } from "./phillipModalAction";
const { ipro, searcher } = roles;
class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* commented code will be use later for improvmnet */}

          {/* {privateRoutesArray().map(route => (
            <PrivateRoute
              key={route.id}
              path={route.path}
              component={route.component}
              {...this.props}
            />
          ))}
          {publicRoutesArray().map(route => (
            <Route
              key={route.id}
              exact={route.name === "default"}
              path={route.path}
              component={route.component}
            />
          ))} */}

          <Route
            exact
            path={publicRoutes.default.path}
            component={publicRoutes.default.component}
          />
          <Route
            exact
            path={publicRoutes.ipro.path}
            component={publicRoutes.ipro.component}
          />
          <Route
            path={publicRoutes.login.path}
            component={publicRoutes.login.component}
          />
          <Route
            path={publicRoutes.resumeAnalyzer.path}
            component={publicRoutes.resumeAnalyzer.component}
          />
          <Route
            path={`${publicRoutes.bottomFeature.path}/:pageId`}
            component={publicRoutes.bottomFeature.component}
          />
          <Route
            path={publicRoutes.mobileLogin.path}
            component={publicRoutes.mobileLogin.component}
          />
          <Route
            path={publicRoutes.redirectedLogin.path}
            component={publicRoutes.redirectedLogin.component}
          />
          <Route
            path={`${publicRoutes.emailverification.path}/:token`}
            component={publicRoutes.emailverification.component}
          />
          <Route
            path={`${publicRoutes.redirectedfrom.path}`}
            component={publicRoutes.redirectedfrom.component}
          />
          <VisitorRoute
            path={publicRoutes.visitorsearch.path}
            component={publicRoutes.visitorsearch.component}
            {...this.props}
          />
          <Route
            path={publicRoutes.Greetings.path}
            component={publicRoutes.Greetings.component}
            {...this.props}
          />

          <AppRoute
            path={appsRoutes.tictell.path}
            component={appsRoutes.tictell.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.embark.path}
            component={appsRoutes.embark.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.sense.path}
            component={appsRoutes.sense.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.headsup.path}
            component={appsRoutes.headsup.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.snapshot.path}
            component={appsRoutes.snapshot.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.assort.path}
            component={appsRoutes.assort.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.philip.path}
            component={appsRoutes.philip.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.philip.path}
            component={appsRoutes.philip.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.nextstep.path}
            component={appsRoutes.nextstep.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <AppRoute
            path={appsRoutes.iproPhilip.path}
            component={appsRoutes.iproPhilip.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.dashboard.path}
            component={privateRoutes.dashboard.component}
            roles={privateRoutes.dashboard.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.createMessage.path}
            component={privateRoutes.createMessage.component}
            roles={privateRoutes.createMessage.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.inboxMessages.path}
            component={privateRoutes.inboxMessages.component}
            roles={privateRoutes.inboxMessages.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.sentMessages.path}
            component={privateRoutes.sentMessages.component}
            roles={privateRoutes.sentMessages.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproNewOpportunity.path}
            component={privateRoutes.iproNewOpportunity.component}
            roles={privateRoutes.iproNewOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproNewOpportunityWithId.path}
            component={privateRoutes.iproNewOpportunityWithId.component}
            roles={privateRoutes.iproNewOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproAccpetedOpportunity.path}
            component={privateRoutes.iproAccpetedOpportunity.component}
            roles={privateRoutes.iproAccpetedOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproDeclinedOpportunity.path}
            component={privateRoutes.iproDeclinedOpportunity.component}
            roles={privateRoutes.iproDeclinedOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherCreateCollaboration.path}
            component={privateRoutes.searcherCreateCollaboration.component}
            roles={privateRoutes.searcherCreateCollaboration.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.resume.path}
            component={privateRoutes.resume.component}
            roles={privateRoutes.resume.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.resumeEdit.path}
            component={privateRoutes.resumeEdit.component}
            roles={privateRoutes.resumeEdit.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newResumeEdit.path}
            component={privateRoutes.newResumeEdit.component}
            roles={privateRoutes.newResumeEdit.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.presentations.path}
            component={privateRoutes.presentations.component}
            roles={privateRoutes.presentations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.statistics.path}
            component={privateRoutes.statistics.component}
            roles={privateRoutes.statistics.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.feedback.path}
            component={privateRoutes.feedback.component}
            roles={privateRoutes.feedback.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.feedbackB.path}
            component={privateRoutes.feedbackB.component}
            roles={privateRoutes.feedbackB.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.about.path}
            component={privateRoutes.about.component}
            roles={privateRoutes.about.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.aboutB.path}
            component={privateRoutes.aboutB.component}
            roles={privateRoutes.aboutB.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.companies.path}
            component={privateRoutes.companies.component}
            roles={privateRoutes.companies.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.shortlists.path}
            component={privateRoutes.shortlists.component}
            roles={privateRoutes.shortlists.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherSentCollaborations.path}
            component={privateRoutes.searcherSentCollaborations.component}
            roles={privateRoutes.searcherSentCollaborations.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherAcceptedCollaborations.path}
            component={privateRoutes.searcherAcceptedCollaborations.component}
            roles={privateRoutes.searcherAcceptedCollaborations.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherInActiveCollaborations.path}
            component={privateRoutes.searcherInActiveCollaborations.component}
            roles={privateRoutes.searcherInActiveCollaborations.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherDeclinedCollaborations.path}
            component={privateRoutes.searcherDeclinedCollaborations.component}
            roles={privateRoutes.searcherDeclinedCollaborations.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.IproCollaborationDeclined.path}
            component={privateRoutes.IproCollaborationDeclined.component}
            roles={privateRoutes.IproCollaborationDeclined.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproNewCollaborations.path}
            component={privateRoutes.iproNewCollaborations.component}
            roles={privateRoutes.iproNewCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.iproActiveCollaborations.path}
            component={privateRoutes.iproActiveCollaborations.component}
            roles={privateRoutes.iproActiveCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.iproInActiveCollaborations.path}
            component={privateRoutes.iproInActiveCollaborations.component}
            roles={privateRoutes.iproInActiveCollaborations.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherCreateOpportunity.path}
            component={privateRoutes.searcherCreateOpportunity.component}
            roles={privateRoutes.searcherCreateOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherDraftOpportunity.path}
            component={privateRoutes.searcherDraftOpportunity.component}
            roles={privateRoutes.searcherDraftOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherSentOpportunity.path}
            component={privateRoutes.searcherSentOpportunity.component}
            roles={privateRoutes.searcherSentOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.iproPhillip.path}
            component={privateRoutes.iproPhillip.component}
            roles={privateRoutes.iproPhillip.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.searcherPhillip.path}
            component={privateRoutes.searcherPhillip.component}
            roles={privateRoutes.searcherPhillip.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.settings.path}
            component={privateRoutes.settings.component}
            roles={privateRoutes.settings.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.resumeSearch.path}
            component={privateRoutes.resumeSearch.component}
            roles={privateRoutes.resumeSearch.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.Network.path}
            component={privateRoutes.Network.component}
            roles={privateRoutes.Network.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.connectedNetwork.path}
            component={privateRoutes.connectedNetwork.component}
            roles={privateRoutes.connectedNetwork.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.invitedNetwork.path}
            component={privateRoutes.invitedNetwork.component}
            roles={privateRoutes.invitedNetwork.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.invitationNetwork.path}
            component={privateRoutes.invitationNetwork.component}
            roles={privateRoutes.invitationNetwork.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.savedSearch.path}
            component={privateRoutes.savedSearch.component}
            roles={privateRoutes.savedSearch.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.snapshot.path}
            component={privateRoutes.snapshot.component}
            roles={privateRoutes.snapshot.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.tictell.path}
            component={privateRoutes.tictell.component}
            roles={privateRoutes.tictell.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.assort.path}
            component={privateRoutes.assort.component}
            roles={privateRoutes.assort.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.embark.path}
            component={privateRoutes.embark.component}
            roles={privateRoutes.embark.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.headsup.path}
            component={privateRoutes.headsup.component}
            roles={privateRoutes.headsup.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.philip.path}
            component={privateRoutes.philip.component}
            roles={privateRoutes.philip.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.iproPhilip.path}
            component={privateRoutes.iproPhilip.component}
            roles={privateRoutes.iproPhilip.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.sense.path}
            component={privateRoutes.sense.component}
            roles={privateRoutes.sense.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.nextstep.path}
            component={privateRoutes.nextstep.component}
            roles={privateRoutes.nextstep.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newResumeSearch.path}
            component={privateRoutes.newResumeSearch.component}
            roles={privateRoutes.newResumeSearch.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newShortlist.path}
            component={privateRoutes.newShortlist.component}
            roles={privateRoutes.newShortlist.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSavedSearch.path}
            component={privateRoutes.newSavedSearch.component}
            roles={privateRoutes.newSavedSearch.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherCreateOpportunity.path}
            component={privateRoutes.newSearcherCreateOpportunity.component}
            roles={privateRoutes.newSearcherCreateOpportunity.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newNearcherSentOpportunity.path}
            component={privateRoutes.newNearcherSentOpportunity.component}
            roles={privateRoutes.newNearcherSentOpportunity.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherDraftOpportunity.path}
            component={privateRoutes.newSearcherDraftOpportunity.component}
            roles={privateRoutes.newSearcherDraftOpportunity.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewCompanies.path}
            component={privateRoutes.NewCompanies.component}
            roles={privateRoutes.NewCompanies.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.newPresentations.path}
            component={privateRoutes.newPresentations.component}
            roles={privateRoutes.newPresentations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherCreateCollaboration.path}
            component={privateRoutes.newSearcherCreateCollaboration.component}
            roles={privateRoutes.newSearcherCreateCollaboration.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherSentCollaborations.path}
            component={privateRoutes.newSearcherSentCollaborations.component}
            roles={privateRoutes.newSearcherSentCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherAcceptedCollaborations.path}
            component={
              privateRoutes.newSearcherAcceptedCollaborations.component
            }
            roles={privateRoutes.newSearcherAcceptedCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherDeclinedCollaborations.path}
            component={
              privateRoutes.newSearcherDeclinedCollaborations.component
            }
            roles={privateRoutes.newSearcherDeclinedCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newSearcherInActiveCollaborations.path}
            component={
              privateRoutes.newSearcherInActiveCollaborations.component
            }
            roles={privateRoutes.newSearcherInActiveCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newIproNewOpportunity.path}
            component={privateRoutes.newIproNewOpportunity.component}
            roles={privateRoutes.iproNewOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.newIproNewOpportunityWithId.path}
            component={privateRoutes.newIproNewOpportunityWithId.component}
            roles={privateRoutes.iproNewOpportunity.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewIproAccpetedOpportunity.path}
            component={privateRoutes.NewIproAccpetedOpportunity.component}
            roles={privateRoutes.NewIproAccpetedOpportunity.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewIproDeclinedOpportunity.path}
            component={privateRoutes.NewIproDeclinedOpportunity.component}
            roles={privateRoutes.iproDeclinedOpportunity.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.NewIproNewCollaborations.path}
            component={privateRoutes.NewIproNewCollaborations.component}
            roles={privateRoutes.NewIproNewCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewIproActiveCollaborations.path}
            component={privateRoutes.NewIproActiveCollaborations.component}
            roles={privateRoutes.NewIproActiveCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewIproInActiveCollaborations.path}
            component={privateRoutes.NewIproInActiveCollaborations.component}
            roles={privateRoutes.NewIproInActiveCollaborations.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.NewIproCollaborationDeclined.path}
            component={privateRoutes.NewIproCollaborationDeclined.component}
            roles={privateRoutes.NewIproCollaborationDeclined.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.newConnectedNetwork.path}
            component={privateRoutes.newConnectedNetwork.component}
            roles={privateRoutes.newConnectedNetwork.roles}
            {...this.props}
          />
          <PrivateRoute
            path={privateRoutes.newInvitedNetwork.path}
            component={privateRoutes.newInvitedNetwork.component}
            roles={privateRoutes.newInvitedNetwork.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.newInvitationNetwork.path}
            component={privateRoutes.newInvitationNetwork.component}
            roles={privateRoutes.newInvitationNetwork.roles}
            {...this.props}
          />

          <PrivateRoute
            path={privateRoutes.newDashboard.path}
            component={privateRoutes.newDashboard.component}
            roles={privateRoutes.newDashboard.roles}
            {...this.props}
          />

          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route exact path="/app-login" component={() => <MobileLogin />} />

          <Route
            path={publicRoutes.noMatch.path}
            component={publicRoutes.noMatch.component}
          />
        </Switch>
      </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const {
    isAuthenticated,
    user: { IsFreelancer },
    logoutAction,
    history,
  } = rest;
  const showModal = useSelector((state) => state?.searcherModal);
  const dispatch = useDispatch();
  let user = StorageService.getUser();

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const role = IsFreelancer ? ipro : searcher;
  const route = window.location.hash.split("#")[1];
  const noOfRoutes = route.split("/");
  if (user?.userFeatures) {
    oldRoutes.map((single) => {
      user.userFeatures.push(single);
    });
  }

  var matches = [];
  let isFeatureAvailable = false;
  const allowedRoutes = ["/setting", "/dashboard", "collaboration", "/resume-edit",
    "opportunity", "opportunities", "/inbox-messages", "/sent-messages", "/create-message",
    "/new-resume-edit", "/resume-search", "/shortlists", "/saved-searches", "/about", "/feedback",
    "/invited-networks", "/invitations-networks", "/connected-networks"

  ];

  if (!user || !user.userFeatures) {
    isFeatureAvailable = false;
    logoutAction();
    logoutApi().then((response) => {
      if (response.success) {
        StorageService.clearAll();
        window.location.href = RESET_LANDINGPAGE_URL;
        return;
      }
    });
  } else {
    user.userFeatures.forEach(function (e) {
      matches = matches.concat(
        e.SubNavigation.filter(function (c) {
          if (c.Url) {
            return c.Url.toLowerCase() == route;
          } else {
            return false;
          }
        })
      );
    });
    isFeatureAvailable =
      user.userFeatures.find((a) => a.Url && a.Url.toLowerCase() == route) ||
        matches[0] ||
        allowedRoutes.some((row) => route.includes(row)) ||
        noOfRoutes.length > 2
        ? true
        : false;
  }
  const handleSkipModal = () => {
    dispatch(onStateChangeActions({ show: "" }));
  };
  const handleSmallModalClick = () => {
    dispatch(onStateChangeActions({ show: "large" }));
  };
  let currentMetaData = appMetaData[route];

  if (currentMetaData) {
    document.title = currentMetaData.title;
    document.querySelector('meta[name="description"]').setAttribute("content", currentMetaData.description);
  } else {
    document.title = appMetaData.prodoo.title;
    document.querySelector('meta[name="description"]').setAttribute("content", appMetaData.prodoo.description);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && isFeatureAvailable ? (
          <ViewWrapper>
            {includes(roles, role) ? (
              <Component
                startTimer={start}
                pauseTimer={pause}
                stopTimer={reset}
                seconds={seconds}
                minutes={minutes}
                hours={hours}
                {...props}
              />
            ) : (
              <Redirect to={privateRoutes.dashboard.path} />
            )}
            <Cookie />
            {showModal?.show != "" && (
              <SearcherPhillip
                open={showModal?.show}
                onSkip={handleSkipModal}
                handleSmallModalClick={handleSmallModalClick}
              // onCloseModal={this.handleClosePhillip}
              // facebookClientId={facebookClientId}
              // linkedInClientId={linkedInClientId}
              // onCompanyPresentationsUpdate={
              //   this.handleUpdateCompanyPresentationsWidget
              // }
              // onResumeShortlistUpdate={
              //   this.handleUpdateResumeShortlistWidgetWidget
              // }
              />
            )}
          </ViewWrapper>
        ) : (
          <Redirect
            to={{
              pathname: publicRoutes.default.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const VisitorRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ViewWrapper>
          <Component {...props} />
        </ViewWrapper>
      )}
    />
  );
};

// const AppRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => (
//         <AppViewWrapper>
//           <Component {...props} />
//         </AppViewWrapper>
//       )}
//     />
//   );
// };

const AppRoute = ({ component: Component, roles, ...rest }) => {
  const {
    isAuthenticated,
    user: { IsFreelancer },
  } = rest;
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });
  const role = IsFreelancer ? ipro : searcher;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <AppViewWrapper>
            {includes(roles, role) ? (
              <Component
                startTimer={start}
                pauseTimer={pause}
                stopTimer={reset}
                seconds={seconds}
                minutes={minutes}
                hours={hours}
                {...props}
              />
            ) : (
              <Redirect to={privateRoutes.dashboard.path} />
            )}
            <Cookie />
          </AppViewWrapper>
        ) : (
          <Redirect
            to={{
              pathname: publicRoutes.default.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
const actions = {
  logoutAction,
};

const mapStateToProps = ({ userInfo }) => {
  const { user, isAuthenticated, cookie } = userInfo;
  return { user, isAuthenticated, cookie };
};
export default connect(mapStateToProps, actions)(Routes);
