import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./emptyinfo.scss";
import { withRouter } from "react-router-dom";
import { privateRoutes } from "../../Routes/routing";
import { connect } from "react-redux";

const EmptyInfo = ({ children, testId, child, showBtn = false, history, labels }) => {

  return (
    <div className="emptySkeletonResult" test-data-id={testId}>
      {ReactHtmlParser(children)}
      {child}
      {showBtn && (
        <div className="button-main">
          <button onClick={() => history.push(privateRoutes.resumeEdit.path)}>{labels?.update_resume}</button>
          <button onClick={() => history.push(privateRoutes.resume.path)}>{labels?.upload_resume}</button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  {
    systemLabel,
  }
) => {
  const { labels } = systemLabel;

  return { labels };
};

export default connect(mapStateToProps, {})(withRouter(EmptyInfo));
